import React, { useState, useEffect, useContext } from 'react';
import './header.css';
import img_1 from '../../img/icon/Molecule_light.png';
import img_1_d from '../../img/icon/Molecule_light_w.png';
import { useGetAllcoursesQuery } from '../../services/aplms';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';
import Question from '../../img/icon/Quiz.png';
import Question_w from '../../img/icon/Quiz_w.png';
import Past from '../../img/icon/Book_open_alt_light.png';
import Past_w from '../../img/icon/Book_open_alt_light_w.png';
import Quiz from '../../img/icon/Book.png';
import Quiz_w from '../../img/icon/Book_w.png';
import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

const Mobileright = (props, key) => {
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <h1></h1>
      </div>
    );
  if (couresdata.isError) return <Error />;
  return (
    <>
      {couresdata.data.map((coures) => {
        return (
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>{coures.name}</Accordion.Header>
            </Accordion.Item>
            <Accordion.Body className="mobile-right-page-header">
              <ul className="list-unstyled megamenu-heading-top">
                <li className="nav-item">
                  <NavLink
                    to={'/Key-Concept/' + coures.slug}
                    className="nav-link text-small pb-0">
                    <img
                      src={img_1}
                      className="d"
                    />{' '}
                    <img
                      src={img_1_d}
                      className="w"
                    />{' '}
                    Key Concepts
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={'/Question-Bank/' + coures.slug}
                    className="nav-link text-small pb-0">
                    <img
                      src={Quiz}
                      className="d"
                    />{' '}
                    <img
                      src={Quiz_w}
                      className="w"
                    />{' '}
                    Question Bank
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={'/Past-Paper-Unit/' + coures.slug}
                    className="nav-link text-small pb-0">
                    <img
                      src={Past}
                      className="d"
                    />
                    <img
                      src={Past_w}
                      className="w"
                    />
                    Past Papers
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to={'/Quiz/' + coures.slug}
                    className="nav-link text-small pb-0">
                    <img
                      src={Question}
                      className="d"
                    />{' '}
                    <img
                      src={Question_w}
                      className="w"
                    />{' '}
                    Quizzes
                  </NavLink>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion>
        );
      })}
    </>
  );
};

export default Mobileright;
