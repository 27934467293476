import { useEffect, useRef } from "react";

const MathJaxRenderer = ({ children }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (window.MathJax && window.MathJax.Hub) {
      window.MathJax.Hub.Queue([
        "Typeset",
        window.MathJax.Hub,
        containerRef.current,
      ]);
    }
  }, [children]);

  return <div ref={containerRef}>{children}</div>;
};

export default MathJaxRenderer;
