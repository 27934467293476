import React from "react";
import "./ap/ap.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Header from "../layout/header/Header";
import All_top_header from "./All_top_header";
import Footer from "../layout/footer/Footer";
import Key from "../img/icon/Molecule_light.png";
import Question from "../img/icon/Quiz.png";
import Past from "../img/icon/Book_open_alt_light.png";
import Quiz from "../img/icon/Book.png";
import Key_w from "../img/icon/Molecule_light_w.png";
import Question_w from "../img/icon/Quiz_w.png";
import Past_w from "../img/icon/Book_open_alt_light_w.png";
import Quiz_w from "../img/icon/Book_w.png";
import { Link } from "react-router-dom";
import { useGetAllcurriculumQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import Subject from "./Subject";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image

const All = () => {
  const curriculum = useGetAllcurriculumQuery();

  if (curriculum.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (curriculum.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (curriculum.isError) return <h1>data not found </h1>;
  return (
    <section className="all-section">
      <img src={BGRight1} alt="Splatter" className="splatter-image-right-1" />
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
      <img src={BGMiddle} alt="Splatter" className="splatter-image-middle" />
      <div className="top-header">
        <Header />
        <All_top_header />
      </div>
      <section className="ap-main-content">
        {curriculum.data.map((curriculumdata) => {
          return (
            <Container>
              <h4 className="top-title">{curriculumdata.name}</h4>
              <Row>
                <Subject propsintroData={curriculumdata.slug} />
              </Row>
            </Container>
          );
        })}
      </section>
      <Footer />
    </section>
  );
};

export default All;
