import React, { useState, useEffect } from "react";
import axios from 'axios';
import Hero from '../../hero/Hero';
import Courses from '../../courses/Courses';
import Brand from '../../brand/Brand';
import Charting from '../../charting/Charting';
import Speaks from '../../speaks/Speaks';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import "./Home.css";

const Home = () => {
  const API_URL = process.env.REACT_APP_API_URL || 'https://num8ers.cloud/api/student';
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check for token in URL parameters (for redirects)
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    

    if (token) {
      localStorage.setItem("token", token);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    // Validate token
    const validateToken = async () => {
      const storedToken = localStorage.getItem('token');

      if (!storedToken) return;

      try {
        await axios.get(`${API_URL}/validate-token`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        });
        // If we get here, token is valid - no action needed
      } catch (error) {
        // Token validation failed, clear localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    };

    validateToken();
  }, [API_URL]);

  useEffect(() => {
    // Trigger the animation on mount
    setLoaded(true);
  }, []);

  return (
    <div className={loaded ? "fade-in" : ""} style={{ overflow: "hidden" }}>
      <Header />
      <Hero />
      <Courses />
      <Brand />
      <Speaks />
      <Charting />
      <Footer />
    </div>
  );
};

export default Home;