import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

// Fade-in animation for the glass container
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container with a soft gradient background
const Container = styled.div`
  background: linear-gradient(
    135deg,
    #f2f9ff 0%,
    #e2f4ee 50%,
    #f2f9ff 100%
  );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
`;

// Glassmorphism styled card
const Glass = styled.div`
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  box-shadow:
    8px 8px 20px rgba(0, 0, 0, 0.1),
    -8px -8px 20px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 450px;
  width: 100%;
  animation: ${fadeIn} 1s ease-out;
`;

// Title styling
const Title = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
`;

// Subtitle styling
const Subtitle = styled.p`
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

// Button styled with a green-themed gradient
const GoBackButton = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 10px;
  background: linear-gradient(45deg, #8cc63f, #6cad2c);
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const ComingSoonPage = () => {
  const history = useHistory();

  // Listen for browser back button events (POP actions)
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        // Redirect to home when back navigation is attempted
        history.replace('/');
      }
    });
    return () => unlisten();
  }, [history]);

  const handleGoBack = (e) => {
    e.preventDefault();
    // Replace current page so that ComingSoonPage is removed from the history stack
    history.replace("/");
  };

  return (
    <Container>
      <Glass>
        <Title>Coming Soon</Title>
        <Subtitle>
          Our content is almost here! Stay tuned for an immersive learning experience.
        </Subtitle>
        <GoBackButton onClick={handleGoBack}>Go Home</GoBackButton>
      </Glass>
    </Container>
  );
};

export default ComingSoonPage;