import React, { useEffect, useState } from "react";
import "./Success.css";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Img_3 from "./../img/Success-Icon.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";

// 1) Import your RTK Query hook & token getter
import { useGetPaymentstatusQuery } from "../services/aplms";
import { getToken } from "../services/LocalStorageService";

import { useHistory } from "react-router-dom";


const Success = () => {
  // We'll keep track of which courses the user has actively subscribed to
  const [activeCourseNames, setActiveCourseNames] = useState([]);

  const history = useHistory();

  // 2) Get the token & fetch the entire payment status data
  const token = getToken();
  const {
    data: paymentData,
    isLoading,
    isSuccess,
  } = useGetPaymentstatusQuery(token);

  useEffect(() => {
    if (isSuccess && paymentData) {
      // 3) Extract the active course names
      const activeCourses = paymentData.purchased_courses || [];
      const courseNames = activeCourses.map((c) => c.name);
      setActiveCourseNames(courseNames);
    }
  }, [isSuccess, paymentData]);

  if (isLoading) {
    return <p>Loading payment info...</p>;
  }
  if (
    !paymentData ||
    !paymentData.subscriptions ||
    paymentData.subscriptions.length === 0
  ) {
    history.push("/payment");
    return null;
  }

  const subscriptions = paymentData.subscriptions;
  const latestSub = subscriptions[subscriptions.length - 1];

  const amountCents = latestSub.stripe_session?.amount_total || 0;
  const amountDollars = (amountCents / 100).toFixed(2);

  const createdSecs = latestSub.stripe_session?.created || 0;
  const paymentDate = new Date(createdSecs * 1000);
  const paymentDateString = paymentDate.toLocaleString();

  const userName = latestSub.stripe_session?.customer_details?.name;

  const items = latestSub.stripe_subscriptions?.items?.data || [];

  

  const rawMethod =
    latestSub.stripe_session?.payment_method_types?.[0] || "N/A";

  // 2) Capitalize the first letter
  const paymentMethod =
    rawMethod.charAt(0).toUpperCase() + rawMethod.slice(1).toLowerCase();

  return (
    <section className="success-page">
      <Header />
      <img
        src={BGRight2}
        alt="Splatter"
        className="splatter-image-right-error"
      />
      <img
        src={BGMiddle}
        alt="Splatter"
        className="splatter-image-middle-error"
      />

      <div className="success-background">
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="success-box d-flex flex-wrap align-items-center">
                <div className="success-box-left text-center me-md-4">
                  <img
                    src={Img_3}
                    alt="Success Icon"
                    className="success-icon"
                  />
                  <p className="success-title">Payment Success!</p>
                  <h4 className="success-amount"> ${amountDollars}</h4>
                </div>
                <div className="success-divider d-none d-md-block" />
                <div className="success-box-right mt-4 mt-md-0">
                  <div className="items-container">
                    {items.map((item, i) => {
                      // The Stripe price ID
                      const priceId = item.price?.id || item.plan?.id;
                      if (!priceId) {
                        return <p key={i}>No plan info for item {i}</p>;
                      }

                      // Match with plantable
                      const matchedPlan = paymentData.plantable.find(
                        (p) => p.planstripe_id === priceId
                      );
                      if (!matchedPlan) {
                        return <p key={i}>Unknown plan: {priceId}</p>;
                      }

                      // Find course name
                      const courseMatch = paymentData.purchased_courses.find(
                        (c) => c.id === matchedPlan.apcourse_id
                      );
                      const courseName = courseMatch
                        ? courseMatch.name
                        : "Unknown Course";
                      const planName = matchedPlan.planname; // e.g. "3 Months"

                      // 1) Get the per-item price
                      const rawItemPriceCents =
                        item.price?.unit_amount || item.plan?.amount || 0;
                      const itemPriceDollars = (
                        rawItemPriceCents / 100
                      ).toFixed(2);

                      return (
                        <p key={i}>
                          <strong>Course:</strong> {courseName} |{" "}
                          <strong>Duration:</strong> {planName} |{" "}
                          <strong>Price:</strong> ${itemPriceDollars}
                        </p>
                      );
                    })}
                  </div>
                  <hr style={{ margin: "1rem 0", borderTop: "1px solid #ccc" }} />
                  <div className="list-payment-box">
                    <p>
                      <span className="label">Ref Number</span>
                      <span className="main-content">
                        {"000" + latestSub.id}
                      </span>
                    </p>
                    <p>
                      <span className="label">Payment Time</span>
                      <span className="main-content"> {paymentDateString}</span>
                    </p>
                    <p>
                      <span className="label">Payment Method</span>
                      <span className="main-content">{paymentMethod}</span>
                    </p>
                    <p>
                      <span className="label">Sender Name</span>
                      <span className="main-content">
                        {" "}
                        {userName || "No name available"}
                      </span>
                    </p>
                    <p>
                      <span className="label">Total Amount</span>
                      <span className="main-content">${amountDollars}</span>
                    </p>
                  </div>

                  {/* Example of using local state for debugging */}
                  {activeCourseNames.length > 0 && (
                    <p style={{ marginTop: "1rem", color: "green" }}>
                      You now have access to:
                      <br />
                      {activeCourseNames.join(", ")}
                    </p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </section>
  );
};

export default Success;
