import React, { useState, useEffect } from "react";
import { Table, Spinner } from "react-bootstrap";
import Switch from "react-switch";
import "./BillingTab.css";
import { useUpgradeSubscriptionMutation } from "../services/aplms";

function useIsMobile(breakpoint = 1100) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth < breakpoint : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isMobile;
}

const BillingTab = ({ paymentData, isPaymentSuccess }) => {
 

  const isMobile = useIsMobile();

  const subscriptions = paymentData?.subscriptions || [];
  const plantable = paymentData?.plantable || [];
  const purchasedCourses = paymentData?.purchased_courses || [];

  let paymentCounter = 0;
  const [autoRenewState, setAutoRenewState] = useState({});

  const token = localStorage.getItem("token");

  const [toggleLoading, setToggleLoading] = useState({});

  const [
    upgradeSubscription,
    { isLoading: isUpgrading, isError, data: upgradeResult },
  ] = useUpgradeSubscriptionMutation();

  // Handler to call when user clicks "Upgrade"
  // Handler to call when user clicks "Upgrade"
  const handleUpgrade = async (subscriptionId, planId) => {
    try {
      // We'll assume you have a token (already do in your code)
      const token = localStorage.getItem("token");

      // Log the payload
    

      // Now call your mutation
      const result = await upgradeSubscription({
        subId: subscriptionId,
        planId: planId,
        token, // If your endpoint needs the Bearer token
      }).unwrap();

      // You can inspect or set state with the result here:
      

      // For example, show a quick alert (replace with your own UI logic)
      alert("Plan upgraded successfully!");
    } catch (error) {
      console.error("Upgrade failed:", error);
      alert("Failed to upgrade plan. Please try again.");
    }
  };

  // Initialize autoRenewState from subscription data on mount/update
  useEffect(() => {
    const initialState = {};
    subscriptions.forEach((sub) => {
      if (sub?.stripe_subscriptions?.items?.data?.length) {
        sub.stripe_subscriptions.items.data.forEach((item) => {
          const planId = item.plan?.id;
          const autoRenewId = `${sub.id}-${planId}`;
          // Set the initial state to the subscription's auto_renew value
          initialState[autoRenewId] = sub.auto_renew;
        });
      }
    });
    setAutoRenewState(initialState);
  }, [subscriptions]);

  const toggleAutoRenew = async (rowId, planId) => {
    const currentAutoRenew =
      autoRenewState[rowId] !== undefined ? autoRenewState[rowId] : true;
    const newAutoRenew = !currentAutoRenew;
    
    // Set loading for this row
    setToggleLoading((prev) => ({ ...prev, [rowId]: true }));

    try {
      const response = await fetch(
        "https://num8ers.cloud/api/student/toggle-autorenew-by-plan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            plan_id: planId,
            auto_renew: newAutoRenew,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
       
        // Update auto renew state so that the switch reflects the new state
        setAutoRenewState((prevState) => ({
          ...prevState,
          [rowId]: newAutoRenew,
        }));
        // Delay the alert slightly (e.g., 100ms) so the UI can update first
        setTimeout(() => {
          alert(`Auto renew turned ${newAutoRenew ? "ON" : "OFF"}`);
        }, 100);
      } else {
        console.error("Failed to toggle auto-renew. Status:", response.status);
        setTimeout(() => {
          alert("Failed to toggle auto-renew. Please try again.");
        }, 100);
      }
    } catch (error) {
      console.error("Error toggling auto-renew:", error);
      alert("Error toggling auto-renew. Please try again.");
    } finally {
      // Reset the loading state
      setToggleLoading((prev) => ({ ...prev, [rowId]: false }));
    }
  };

  return (
    <div className="">
      <div className="myprofile-password bt-border-none pt-30 pb-30">
        <h4 className="payment-table-header">Your recent payments:</h4>
        {/* Responsive container for horizontal scrolling on mobile */}

        <Table responsive={isMobile} className="Billing_table pt-30">
          <thead>
            <tr>
              <th>Payment No</th>
              <th>Course</th>
              <th>Plan</th>
              <th>Price</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Invoice PDF</th>
              <th>Auto Renew</th>
            </tr>
          </thead>
          <tbody>
            {isPaymentSuccess && subscriptions.length > 0 ? (
              subscriptions.map((sub, index) => {
                // If there's no Stripe info, assume it's a free promo subscription.
                if (!sub?.stripe_subscriptions) {
                  // If your backend returns a plan identifier for free subs, use that.
                  const freePlanId = sub.promo_plan_id; // adjust based on your actual property name
                  const foundPlan = plantable.find((p) => p.id === freePlanId);
                  const planName = foundPlan ? foundPlan.planname : "Free Plan";
                  const foundCourse = purchasedCourses.find(
                    (c) => foundPlan && c.id === foundPlan.apcourse_id
                  );
                  const courseName = foundCourse
                    ? foundCourse.name
                    : "Free Course";
                  return (
                    <tr key={`sub-${sub.id}-free`}>
                      <td>{index + 1}</td>
                      <td>{courseName}</td>
                      <td>{planName}</td>
                      <td>$0.00</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>
                        {sub.invoice_pdf ? (
                          <a
                            href={sub.invoice_pdf}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Invoice
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>Free Subscription</td>
                    </tr>
                  );
                } else {
                  // Process Stripe subscriptions as before.
                  const stripeSub = sub.stripe_subscriptions;
                  const subscriptionItems = stripeSub.items?.data || [];
                  return subscriptionItems.map((item, idx) => {
                    const planId = item.plan?.id;
                    const foundPlan = plantable.find(
                      (p) => p.planstripe_id === planId
                    );
                    const planName = foundPlan ? foundPlan.planname : "N/A";
                    const foundCourse = purchasedCourses.find(
                      (c) => foundPlan && c.id === foundPlan.apcourse_id
                    );
                    const courseName = foundCourse ? foundCourse.name : "N/A";
                    const priceDisplay =
                      item.plan?.amount !== undefined
                        ? `$${(item.plan.amount / 100).toFixed(2)}`
                        : "N/A";
                    const startDate = new Date(
                      stripeSub.start_date * 1000
                    ).toLocaleDateString();
                    const endDate = new Date(
                      stripeSub.current_period_end * 1000
                    ).toLocaleDateString();
                    // Unique ID for toggling auto-renew
                    const autoRenewId = `${sub.id}-${planId}`;
                    return (
                      <tr key={`payment-item-${index}-${idx}`}>
                        <td>{index + 1}</td>
                        <td>{courseName}</td>
                        <td>{planName}</td>
                        <td>{priceDisplay}</td>
                        <td>{startDate}</td>
                        <td>{endDate}</td>
                        <td>
                          {sub.invoice_pdf ? (
                            <a
                              href={sub.invoice_pdf}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Invoice
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {sub.status !== "active" ? (
                            <span>Plan Ended</span>
                          ) : toggleLoading[autoRenewId] ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              variant="success"
                            />
                          ) : (
                            <Switch
                              onChange={() =>
                                toggleAutoRenew(autoRenewId, planId)
                              }
                              checked={autoRenewState[autoRenewId]}
                              offColor="#ccc"
                              onColor="#5b9311"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={20}
                              width={48}
                              handleDiameter={22}
                              offHandleColor="#000"
                              onHandleColor="#000"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  });
                }
              })
            ) : (
              <tr>
                <td colSpan="8">No subscriptions found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default BillingTab;
