// src/components/Logout.js
import React, { useEffect } from 'react';
import { useLogoutUserMutation, useGetLoggedUserQuery, useGetPaymentstatusQuery } from '../../services/aplms';
import { getToken, removeToken, removeUserinfo } from '../../services/LocalStorageService';
import { setUserInfo, unsetUserInfo, setUserCourses } from '../../features/userSlice';
import { unsetUserToken } from '../../features/authSlice';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getToken();

  const [logoutUser] = useLogoutUserMutation();
  const { data: userData, isSuccess: isUserSuccess } = useGetLoggedUserQuery(token);
  const { data: paymentData, isSuccess: isPaymentSuccess } = useGetPaymentstatusQuery(token);


  // Store user ID in localStorage only when userData and userData.user exist
  useEffect(() => {
    if (userData && userData.user && userData.user.id) {
      localStorage.setItem("id", userData.user.id);
    }
  }, [userData]);

  // 🔹 Store User Data in Redux
  useEffect(() => {
    if (isUserSuccess && userData?.student) {

      const { email = "", first_name = "", last_name = "", phone = "", status = "", plan_start, plan_end } = userData.student;
      const fullName = `${first_name} ${last_name}`.trim();

      dispatch(setUserInfo({ email, name: fullName, phone, status, plan_start, plan_end }));
    }
  }, [userData, isUserSuccess, dispatch]);

  // 🔹 Store Full Purchased Course Data in Redux
  useEffect(() => {
    if (isPaymentSuccess && paymentData?.purchased_courses) {
      const purchasedCourses = paymentData.purchased_courses;
      const subscriptions = paymentData.subscriptions || [];
      const plantable = paymentData.plantable || [];



      let formattedCourses = [];

      purchasedCourses.forEach((course) => {
        const matchingPlans = plantable.filter((plan) => plan.apcourse_id === course.id);

        if (matchingPlans.length === 0) {
          console.warn(`⚠️ No matching plan found for course ID: ${course.id}`);
        }

        matchingPlans.forEach((plan) => {
          const matchingSubscription = subscriptions.find((sub) =>
            sub.stripe_subscriptions?.items?.data.some((item) => item.plan.id === plan.planstripe_id)
          );

          if (!matchingSubscription) {
            console.warn(`⚠️ No matching subscription found for course ID: ${course.id} with plan ID: ${plan.planstripe_id}`);
          }

          formattedCourses.push({
            course_id: course.id,
            course_name: course.name,
            plan_id: plan.planstripe_id || "N/A",
            plan_name: plan.planname || "Unknown Plan",
            end_date: matchingSubscription?.stripe_subscriptions?.current_period_end || "N/A",
            invoice_pdf: matchingSubscription?.invoice_pdf || null,
          });
        });
      });



      dispatch(setUserCourses(formattedCourses));
    }
  }, [paymentData, isPaymentSuccess, dispatch]);

  useEffect(() => {
    if (isPaymentSuccess && paymentData?.subscriptions?.length) {
      // Check if any subscription has a promocode_id that's not null or undefined
      const hasMasterPromo = paymentData.subscriptions.some(
        (subscription) => subscription.promocode_id != null
      );

      if (hasMasterPromo) {

        dispatch(setUserInfo({ masterPromo: true }));
      }
    }
  }, [paymentData, isPaymentSuccess]);



  // 🔹 Handle Logout
  const handleLogout = async () => {
    try {
      const response = await logoutUser({ token }).unwrap();

      if (response?.status === 'success') {

        dispatch(unsetUserToken({ token: null }));
        dispatch(unsetUserInfo());
        dispatch(setUserCourses([]));
        removeToken('token');
        removeToken('id');
        removeUserinfo('userinfo');
        dispatch(setUserInfo({ masterPromo: false }));


        history.push('/');
      }
    } catch (error) {
      console.error("❌ Logout Error:", error);
    }
  };
  return <NavLink to="/" onClick={handleLogout} className="logout-link">Logout</NavLink>;
};

export default Logout;