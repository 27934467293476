import React from "react";
import "../key-concept/keyconcept.css";
import { NavLink, useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import Notebook_light from "../img/icon/notebook_light.png";
import Right_arror from "../img/icon/right-arror.png";
import Right_arror_w from "../img/icon/Expand_right_light_w.png";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import { useGetAllunitQuery } from "../services/aplms";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-scroll";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image
import { useSelector } from "react-redux";
import QuizTopic from "./QuizTopic";
import ComingSoonPage from "../ComingSoonPage";

const Key_concept = (props) => {
  const history = useHistory();

  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);
  const masterPromo = useSelector((state) => state.user.masterPromo);

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  const unitdata = useGetAllunitQuery(props.match.params.slug);
  if (unitdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

    if (!unitdata.data || unitdata.data.length === 0) {
      return <ComingSoonPage />;
    }

  
  if (unitdata.isError) return <h1>An error occured {unitdata.error.error}</h1>;

  const isCourseAccessible = (courseId) => {
    if (masterPromo === true) {
      return true;
    }

    const course = purchasedCourses.find((c) => c.course_id === courseId);

    if (!course) return false; // Not purchased

    const today = new Date().getTime() / 1000; // Convert current time to Unix timestamp
    return course.end_date >= today; // Returns true if plan is active
  };

  const neededCourseId = unitdata.data[0]?.apcourse_id; // <-- use apcourse_id

  if (!isCourseAccessible(neededCourseId)) {
    history.push("/payment");
    return null; // stop rendering, so no further UI is shown
  }

  return (
    <section className="quiz-section">
      <div className="top-header-quiz">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <img src={BGMiddle} alt="Splatter" className="splatter-image-middle" />
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={"/AP/" + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].curriculaname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={"/AP/" + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].subjname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {unitdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>Quiz</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2> {unitdata.data[0].couresname}- Quiz</h2>
                  <Row>
                    <Col sm={8}>
                      <div className="hide-on-mobile">
                        <div className="course-description">
                          {HTMLReactParser(unitdata.data[0].couresdescription)}
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" />
                        <img src={qu_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Dropdown>
                  <Dropdown.Toggle className="unit-button" id="dropdown-basic">
                    <span>
                      <img src={Notebook_light} />
                      Jump to
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="unit-menu">
                    {unitdata.data.map((unit) => {
                      return (
                        <Dropdown.Item to={unit.unitnumber}>
                          <Link to={unit.unitnumber}>
                            Unit {unit.unitnumber}
                          </Link>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="ap-main-content">
        <Container className="mb-120">
        <QuizTopic unitData={unitdata.data} />
        </Container>
      </section>
      <Footer />
    </section>
  );
};

export default Key_concept;
