import React, { useState } from "react";
import { getToken } from "../services/LocalStorageService";
import { useChangeUserPasswordMutation } from "../services/aplms";
import { useSelector } from "react-redux";
import "./ChangePassword.css";
import "./myprofile.css"
import Button from "react-bootstrap/Button";

const Changepassword = () => {
  const [formData, setFormData] = useState({
    oldpassword: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  }); 

  const [changeUserPassword] = useChangeUserPasswordMutation();
  const token = getToken();
  const myData = useSelector((state) => state.user);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { oldpassword, password, confirm_password } = formData;

    if (oldpassword && password && confirm_password) {
      if (password === confirm_password) {
        try {
          const res = await changeUserPassword({ actualData: formData, token });
          if (res?.data?.status === "success") {
            setFormData({ oldpassword: "", password: "", confirm_password: "" });
            setError({
              status: true,
              msg: "Password Changed Successfully",
              type: "success",
            });
          } else {
            setError({
              status: true,
              msg: res?.data?.msg || "Something went wrong!",
              type: "error",
            });
          }
        } catch (err) {
          setError({
            status: true,
            msg: "Failed to change password. Please try again.",
            type: "error",
          });
        }
      } else {
        setError({
          status: true,
          msg: "Password and Confirm Password don't match",
          type: "error",
        });
      }
    } else {
      setError({
        status: true,
        msg: "All fields are required",
        type: "error",
      });
    }
  };

  return (
    <section className="change-password-section">
      <h2 className="change-password-heading">Change Password</h2>
      {error.status && (
        <p
          className={`alert ${
            error.type === "success" ? "alert-success" : "alert-danger"
          }`}
        >
          {error.msg}
        </p>
      )}

      <form id="password-change-form" onSubmit={handleSubmit}>
        {/* Old Password */}
        <div className="row mb-3 align-items-center">
          <label
            htmlFor="oldpassword"
            className="col-sm-2 col-form-label text-start"
          >
            Old Password
          </label>
          <div className="col-sm-6">
            <input
              type="password"
              name="oldpassword"
              id="oldpassword"
              placeholder="Enter Old Password"
              className="form-control"
              value={formData.oldpassword}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* New Password */}
        <div className="row mb-3 align-items-center">
          <label htmlFor="password" className="col-sm-2 col-form-label text-start">
            New Password
          </label>
          <div className="col-sm-6">
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter New Password"
              className="form-control"
              value={formData.password}
              onChange={handleChange}
              minLength="8"
              required
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$"
              title="Must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
            />
          </div>
        </div>

        {/* Confirm Password */}
        <div className="row mb-4 align-items-center">
          <label
            htmlFor="confirm_password"
            className="col-sm-2 col-form-label text-start"
          >
            Confirm Password
          </label>
          <div className="col-sm-6">
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm New Password"
              className="form-control"
              value={formData.confirm_password}
              onChange={handleChange}
              minLength="8"
              required
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$"
              title="Must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
            />
          </div>
        </div>

    
            <Button type="submit" className="save-changes-btn">
              Save Changes
            </Button>

      </form>
    </section>
  );
};

export default Changepassword;
