import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux"; // <-- Import from Redux

import "./questions.css";
import HTMLReactParser from "html-react-parser";
import { useGetSearchquestionQuery } from "../services/aplms";
import { useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Loader from "../loader/Loader";
import Modal from "react-bootstrap/Modal";

const Questions = (props) => {
  const [model, setModel] = useState(false);
  const [tempdata, setTempdata] = useState();
  const [videomodel, setvideoModel] = useState(false);
  const [videodata, setVideodata] = useState();

  // Subscription data from Redux
  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);
  const masterPromo = useSelector((state) => state.user.masterPromo);

  // Checks if user is subscribed to a given courseId and not expired
  const isCourseAccessible = (courseId) => {

    if (masterPromo) {
      return true;
    }


    // 1) Find the matching course
    const course = purchasedCourses.find((c) => c.course_id === courseId);


    // 2) If no match, user hasn't purchased
    if (!course) {

      return false;
    }

    // 3) Check if subscription is still active
    const now = Math.floor(new Date().getTime() / 1000);
  
    const hasAccess = course.end_date >= now;
    
    return hasAccess;
  };

  const getData = (photo) => {
    let tempData = [photo];
    setTempdata((catdata) => [1, ...tempData]);
    return setModel(true);
  };

  const getvideoData = (video) => {
    let videoData = [video];
    setVideodata((catdata) => [1, ...videoData]);
    return setvideoModel(true);
  };

  // Query the question data
  const questiondata = useGetSearchquestionQuery(props.match.params.slug);

  // Modals
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkScheme, setCurrentMarkScheme] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const history = useHistory();

  useEffect(() => {
    // if (!localStorage.getItem('token')) {
    //   history.push('/login');
    // }
  }, []);

  // Helper to extract image src from HTML
  function extractImageUrl(htmlString) {
    if (!htmlString) return null;
    const match = htmlString.match(/<img [^>]*src=["']([^"']+)["'][^>]*>/i);
    return match ? match[1] : null;
  }

  // Modals handlers
  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };
  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };
  const handleOpenMarkSchemeModal = (markSchemeHTML) => {
    const imageURL = extractImageUrl(markSchemeHTML);
    setCurrentMarkScheme(imageURL);
    setShowMarkSchemeModal(true);
  };
  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
    setCurrentMarkScheme(null);
  };
  const handleOpenVideoModal = (videoURL) => {
    setCurrentVideo(videoURL);
    setShowVideoModal(true);
  };
  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideo(null);
  };

  // Checking loading/error status
  if (questiondata.isLoading) {

    return <Loader />;
  }
  if (questiondata.isError) {

    return <h1>An error occurred {questiondata.error.error}</h1>;
  }

  // Logging the fetched question data


  // USE apcourse_id HERE:
  const neededCourseId = questiondata?.data?.[0]?.apcourse_id;


  // Check subscription
  if (!isCourseAccessible(neededCourseId)) {

    history.push("/payment");
    return null; // stop rendering
  }

  return (
    <>
      <section className="section-1">
        <Header />
        <div className="searchquestion-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-1-content">
                  <h2>We've found your question.</h2>
                  <p>
                    Whether you're revising or practicing, we’ve got past
                    papers, detailed solutions, and topic-based question banks
                    to help you succeed. Explore your matched result below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {questiondata.data.map((catdata, idx) => (
        <section className="unit-page" key={idx}>
          <div className="container unit-page-main-style">
            <div className="row">
              <div className="col-md-12">
                <div className="unit-page-main-top-heading">
                  {/* <h4>Question {catdata.questionnumber}</h4> */}
                </div>
              </div>
            </div>

            <div className="question-part">
              <header>
                {/* <h4>{catdata.name}</h4> */}
                <div className="choices"></div>
                <div className="meta">
                  <span className="marks" data-value="1">
                    Difficulty: {catdata.status}
                  </span>
                </div>
              </header>
              <div className="question-content no-select">
                <div className="question-problem">
                  <div className="block">
                    <p>{HTMLReactParser(catdata.description)}</p>
                  </div>
                  <ul className="question-choices"></ul>
                </div>
              </div>
              <footer>
                <div className="choices"></div>
                <div className="buttons">
                  <div className="popup-button-video-and-photo">
                    <button
                      className="chip"
                      onClick={() => handleOpenFormulaModal(catdata.formula)}
                    >
                      Formulas
                    </button>
                    <button
                      className="chip"
                      onClick={() => handleOpenMarkSchemeModal(catdata.photo)}
                    >
                      Mark Scheme
                    </button>

                    {/* Video Answer (loop through all videos) */}
                    {catdata.video.split(",").map((video, index) => (
                      <button
                        key={index}
                        className="chip"
                        onClick={() => handleOpenVideoModal(video)}
                      >
                        Video Answer
                      </button>
                    ))}
                  </div>
                </div>
              </footer>
            </div>
          </div>

          {/* Formula Modal */}
          <Modal
            show={showFormulaModal}
            onHide={handleCloseFormulaModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Formula</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentFormulaImage && (
                <iframe
                  src={currentFormulaImage}
                  style={{ width: "100%", height: "600px" }}
                  title="Formula PDF"
                />
              )}
            </Modal.Body>
          </Modal>

          {/* Mark Scheme Modal */}
          <Modal
            show={showMarkSchemeModal}
            onHide={handleCloseMarkSchemeModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Mark Scheme</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentMarkScheme && (
                <img
                  src={currentMarkScheme}
                  alt="Mark Scheme"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Modal.Body>
          </Modal>

          {/* Video Modal */}
          <Modal
            show={showVideoModal}
            onHide={handleCloseVideoModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Video Answer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentVideo && (
                <iframe
                  src={currentVideo}
                  style={{ width: "100%", height: "400px" }}
                  title="Video Answer"
                  frameBorder="0"
                  allowFullScreen
                />
              )}
            </Modal.Body>
          </Modal>
        </section>
      ))}
    </>
  );
};

export default Questions;
