import React, { useContext, useState, useEffect } from "react";
import "./Result.css";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import chack from "../img/icon/chack.png";
import chack_wrong from "../img/icon/wrong.png";
import result_bottom from "../img/icon/result_bottom.png";
import { QuizContext } from "./Context/QuizHolder";
import { Redirect } from "react-router-dom";
import BGRight2 from "../img/Bg Right 2.png";

const Result = () => {
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);

  const [answerIndicators, setAnswerIndicators] = useState([]);
  const { quizzes, setQuizzes } = useContext(QuizContext);
  const [resultProcessed, setResultProcessed] = useState(false);

  useEffect(() => {
    // Only run this effect once when the component mounts
    if (!resultProcessed && quizzes && quizzes.questions) {
      // Get stored answers
      const storedAnswerIndicators =
        JSON.parse(localStorage.getItem("answerIndicators")) || [];

      // Clean indicators
      const cleanedIndicators = storedAnswerIndicators
        .filter((indicator) => indicator !== null && indicator !== undefined)
        .map((indicator) => (indicator === 1 ? 1 : 0));

      // Truncate to match question count
      const trimmedIndicators = cleanedIndicators.slice(
        0,
        quizzes.questions.length
      );

      // Calculate correct count
      const correctCount = trimmedIndicators.filter(
        (indicator) => indicator === 1
      ).length;

      // Update state only once
      setAnswerIndicators(trimmedIndicators);

      // Update localStorage
      localStorage.setItem(
        "answerIndicators",
        JSON.stringify(trimmedIndicators)
      );
      localStorage.setItem("quizResult", correctCount.toString());

      // Update quiz result in context (only if different)
      if (quizzes.result !== correctCount) {
        setQuizzes((prev) => ({
          ...prev,
          result: correctCount,
        }));
      }

      // Mark as processed so we don't run this effect again
      setResultProcessed(true);
    }
  }, [quizzes, setQuizzes, resultProcessed]);

  // Check for empty quizzes
  if (!quizzes || !quizzes.questions || quizzes.questions.length === 0) {
    return <Redirect to="/" />;
  }

  // Calculate correct/wrong answers
  const correctAnswers = answerIndicators.filter(
    (indicator) => indicator === 1
  ).length;
  const wrongAnswers = quizzes.questions.length - correctAnswers;

  // Calculate score percentage
  const scorePercentage = (correctAnswers / quizzes.questions.length) * 100;

  return (
    <section className="quiz-section">
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />

      <Header />
      <div className="result-container">
        <div className="result-header">
          <h2>Quiz Results</h2>
          <p>
            See how well you did on your {quizzes.questions[0].couresname} quiz
          </p>
        </div>

        <Row>
          <Col md={12} lg={10} className="mx-auto">
            <div className="result-summary-card">
              <Row>
                {/* Questions list column */}
                <Col md={6}>
                  <div className="questions-list">
                    {quizzes.questions.map((unit, index) => (
                      <div className="question-result-item" key={index}>
                        <p>Question {index + 1}</p>
                        <div
                          className={`result-indicator ${
                            answerIndicators[index] === 1
                              ? "correct"
                              : "incorrect"
                          }`}
                        >
                          <img
                            src={
                              answerIndicators[index] === 1
                                ? chack
                                : chack_wrong
                            }
                            alt={
                              answerIndicators[index] === 1
                                ? "Correct"
                                : "Incorrect"
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>

                {/* Score summary column */}
                <Col md={6}>
                  <div className="score-summary">
                    <h3>Quiz Summary</h3>
                    <div className="score-detail">
                      <span className="score-label">Total Questions:</span>
                      <span className="score-value">
                        {quizzes.questions.length}
                      </span>
                    </div>
                    <div className="score-detail">
                      <span className="score-label">Correct Answers:</span>
                      <span className="score-value">{correctAnswers}</span>
                    </div>
                    <div className="score-detail">
                      <span className="score-label">Wrong Answers:</span>
                      <span className="score-value">{wrongAnswers}</span>
                    </div>
                  </div>

                  <div className="percentage-display">
                    <p>Your Score: {scorePercentage.toFixed(2)}%</p>
                  </div>

                  <div className="final-score">
                    <p>You Got {correctAnswers} NP</p>
                    <img src={result_bottom} alt="Result" />
                  </div>
                </Col>
              </Row>
            </div>

            {/* Action buttons */}
            <div className="action-buttons">
              <div className="action-button">
                <Link
                  to={"/Quiz-Question/" + quizzes.questions[0].apunitsslug}
                  className="quiz-btn"
                >
                  Start Again
                </Link>
              </div>
              <div className="action-button">
                <Link to="/" className="quiz-btn">
                  Back to Home
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </section>
  );
};

export default Result;
