import React, { useContext, useEffect, useState } from "react";
import "./quiz.css";
import { Link, useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import { useGetAllquizquctionQuery } from "../services/aplms";
import HTMLReactParser from "html-react-parser";
import happy_img from "../img/happy_light.png";
import sad_img from "../img/Sad_light.png";
import Quizpopup from "./Quizpopup";
import { QuizContext } from "./Context/QuizHolder";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";
import MathJaxRenderer from "../MathJaxRenderer";

const Box = ({ current, next, props }) => {
  const questiondata = useGetAllquizquctionQuery(props.match.params.slug);
  const history = useHistory();
  const [model, setModel] = useState(false);
  const [check, setCheck] = useState(false);
  const [tempdata, setTempdata] = useState();
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [correct, setCorrect] = useState(null);
  const [result, setResult] = useState(0);
  const [classadd, setClassadd] = useState("");
  const [disabledcheck, setDisabledcheck] = useState(false);
  const [ans, setAns] = useState("");
  const [exit, setExit] = useState(null);
  const { quizzes, setQuizzes } = useContext(QuizContext);
  const [timer, setTimer] = useState(45);
  const [timerInterval, setTimerIntervalState] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null); // Added to store correct answer

  const getData = (photo) => {
    let tempData = [photo];
    setTempdata((catdata) => [1, ...tempData]);
    return setModel(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA"
      ) {
        // Don't handle shortcuts when typing in input fields
        return;
      }

      switch (event.key.toLowerCase()) {
        case "enter":
          if (disabledcheck) {
            // Check if we're at the end of the quiz before calling nextHandler
            const quizData = quizzes;
            if (current + 1 >= quizData?.questions?.length) {
              // Navigate to results page instead of calling nextHandler
              history.push("/Result");
            } else {
              // Call nextHandler only if there are more questions
              nextHandler();
            }
          } else if (check) {
            // If 'Check' button is active
            saveHandler();
          }
          break;
        case "a":
        case "b":
        case "c":
        case "d":
          handleSelectAnswer(event.key.toUpperCase());
          break;
        case "r":
          handleResetExercise();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [check, disabledcheck, ans, current, quizzes, history]);

  // Define handler functions
  const handleSelectAnswer = (key) => {
    setAns(key);
    setCheck(true);
  };

  const handleResetExercise = () => {
    // Implement the logic to reset the exercise
    console.log("Exercise reset");
    // Reset state variables
    setAns("");
    setCheck(false);
    setDisabledcheck(false);
    setCorrect(null);
    setCorrectAnswer(null);
  };

  // Start timer
  useEffect(() => {
    // Clear previous interval if exists
    if (timerInterval) clearInterval(timerInterval);

    // Set new timer interval
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    setTimerIntervalState(interval);

    // Cleanup on component unmount or when moving to next question
    return () => clearInterval(interval);
  }, [current]);

  useEffect(() => {
    if (questiondata.data) {
      setQuizzes(questiondata.data);
      setCorrect(null);
      setCorrectAnswer(null); // Reset correct answer indicator
      // Remove the setExit call completely - we'll handle this in saveHandler
      // Reset timer when new questions load
      setTimer(45);
    }
  }, [questiondata.data, setQuizzes]);

  // Handler for saving the answer and updating UI
  const saveHandler = () => {
    console.log("--- saveHandler called ---");
    const quizData = quizzes;

    if (!quizData || !quizData.questions) {
      console.error("Quiz data missing, cannot save answer");
      return;
    }

    // Clear timer interval
    if (timerInterval) clearInterval(timerInterval);

    const correctAnswerValue = quizData.questions[current].answer;
    console.log(
      `Current question ${
        current + 1
      }, correct answer: ${correctAnswerValue}, user answer: ${ans}`
    );

    setCorrectAnswer(correctAnswerValue); // Store the correct answer for highlighting
    const answerIndicator = correctAnswerValue === ans ? 1 : 0;

    // Retrieve stored answer indicators (1 for correct, 0 for incorrect)
    let storedIndicators =
      JSON.parse(localStorage.getItem("answerIndicators")) || [];
    console.log("Previous answerIndicators:", storedIndicators);

    // Update the current question's indicator in localStorage
    storedIndicators[current] = answerIndicator;
    localStorage.setItem("answerIndicators", JSON.stringify(storedIndicators));
    console.log("Updated answerIndicators:", storedIndicators);

    // Get current result
    const storedResult = parseInt(
      localStorage.getItem("quizResult") || "0",
      10
    );
    console.log("Current stored result:", storedResult);

    // Check if the selected answer is correct
    if (correctAnswerValue === ans) {
      setClassadd(correctAnswerValue);

      // Calculate new result
      // Note: Since we're updating answerIndicators one at a time, we can just count correct answers
      const correctCount = storedIndicators.filter(
        (indicator) => indicator === 1
      ).length;
      console.log("Correct answer count from indicators:", correctCount);

      // Store the updated result in localStorage for persistence
      localStorage.setItem("quizResult", correctCount.toString());
      console.log("Saving result to localStorage:", correctCount);

      // Update context with new result
      setQuizzes((prevQuizzes) => {
        const updatedQuizzes = {
          ...prevQuizzes,
          result: correctCount,
        };
        console.log("Updated quizzes context:", updatedQuizzes);
        return updatedQuizzes;
      });

      // Update local state to reflect new score
      setResult(correctCount);
      console.log("Local result state updated:", correctCount);

      // Set feedback UI for correct answer
      setCorrect(
        <>
          {current + 1 === quizData.questions.length ? (
            <Link to="/Result" className="next-btn active">
              Show Result
            </Link>
          ) : (
            <button
              onClick={() => {
                nextHandler();
              }}
              className="next-btn active"
            >
              Continue
            </button>
          )}
          <div className="feedback-container">
            <div className="feedback correct">
              <div className="feedback-icon">✓</div>
              <h3 className="Incorrect-Text">Correct!</h3>
            </div>
          </div>
        </>
      );

      // Show confetti for correct answer
      showConfetti();
    } else {
      // Incorrect answer case - no result update
      setClassadd("wrong");

      // Set feedback UI for incorrect answer
      setCorrect(
        <>
          {current + 1 === quizData.questions.length ? (
            <Link to="/Result" className="next-btn active">
              Show Result
            </Link>
          ) : (
            <button
              onClick={() => {
                nextHandler();
              }}
              className="next-btn active"
            >
              Continue
            </button>
          )}
          <div className="feedback-container">
            <div className="feedback incorrect">
              <div className="feedback-icon">✗</div>
              <h3 className="Incorrect-Text">Incorrect!</h3>
            </div>

            <button
              className="explanation-btn"
              onClick={() => getData(quizzes.questions[current].fullanswer)}
            >
              <span className="explanation-icon">💡</span>
              See Explanation
            </button>
          </div>
        </>
      );
    }

    // Handle end of quiz case
    if (current + 1 === quizData.questions.length) {
      console.log("End of quiz reached");
      setExit(
        <>
          <Link to="/Result" className="next-btn active">
            Show Result
          </Link>
        </>
      );
      setDisabledcheck(true);
    } else {
      setDisabledcheck(true);
    }

    // Reset 'check' state
    setCheck(false);
  };
  // Show confetti animation for correct answers
  const showConfetti = () => {
    const quizContainer = document.querySelector(".quiz-container");
    if (!quizContainer) return;

    // Create several confetti elements
    for (let i = 0; i < 30; i++) {
      const confetti = document.createElement("div");
      confetti.className = "confetti";
      confetti.style.left = `${Math.random() * 100}%`;
      confetti.style.top = "0";
      confetti.style.width = `${Math.random() * 10 + 5}px`;
      confetti.style.height = `${Math.random() * 10 + 5}px`;
      confetti.style.background = ["#5b9311", "#8EC63F", "#ff7e00", "#2f2f2f"][
        Math.floor(Math.random() * 4)
      ];
      confetti.style.borderRadius = Math.random() > 0.5 ? "50%" : "0";
      confetti.style.opacity = "0";

      quizContainer.appendChild(confetti);

      // Animate the confetti
      confetti.style.animation = `confettiFall ${
        Math.random() * 2 + 1
      }s ease-out forwards`;
      confetti.style.animationDelay = `${Math.random() * 0.5}s`;

      // Remove confetti after animation
      setTimeout(() => {
        confetti.remove();
      }, 3000);
    }
  };

  // Modified nextHandler function
  const nextHandler = () => {
    const quizData = quizzes;
    if (
      !quizData ||
      !quizData.questions ||
      current + 1 >= quizData.questions.length
    ) {
      // If we're at the end of the quiz, navigate to results page
      history.push("/Result");
      return;
    }

    // Otherwise proceed to the next question
    next(current + 1);
    setDisabledcheck(false);
    setClassadd("");
    setCorrect(null);
    setCheck(false);
    setAns("");
    setCorrectAnswer(null);
    setTimer(45);
  };

  // Check if questiondata.data or questions is undefined or empty
  if (questiondata.isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (questiondata.isError) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  if (
    !questiondata.data ||
    !questiondata.data.questions ||
    questiondata.data.questions.length === 0
  ) {
    history.push("/comingsoonpage");
    return null;
  }

  return (
    <>
      <div className="quiz-section">
        {/* <img src={BGRight1} alt="" className="splatter-image-right-1" /> */}
        {/* <img src={BGRight2} alt="" className="splatter-image-right-2" />
        <img src={BGMiddle} alt="" className="splatter-image-middle" /> */}

        <Header />

        <section className="unit-page">
          <Container>
            <Row>
              <Col>
                <div className="quiz-container with-3d-effect">
                  <div className="quiz-header">
                    <h1>{questiondata.data.questions[0].couresname} - Quiz</h1>
                    <p className="subtitle">
                      Test your understanding of the concepts
                    </p>

                    <div className="progress-container">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${
                            ((current + 1) /
                              questiondata.data.questions.length) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>

                    <div className="stats-bar">
                      <div className="timer">
                        <div className="timer-icon">⏱️</div>
                        <span>
                          {timer < 10 ? `00:0${timer}` : `00:${timer}`}
                        </span>
                      </div>

                      {/* Add this question counter */}
                      <div className="question-counter">
                        <div className="counter-icon">📝</div>
                        <span>
                          Question {current + 1}/
                          {questiondata.data.questions.length}
                        </span>
                      </div>

                      <div className="score">
                        <div className="score-icon">🏆</div>
                        <span>Score: {result}</span>
                      </div>
                    </div>
                  </div>

                  <div className="question-container">
                    <div className="question-box">
                      <div className="question-number">{current + 1}</div>

                      <div className="question">
                        <MathJaxRenderer>
                          {HTMLReactParser(
                            questiondata.data.questions[current].question
                          )}
                        </MathJaxRenderer>
                      </div>
                    </div>

                    <div className="options-container">
                      <div
                        className={`option ${ans === "A" ? "selected" : ""} 
                                  ${
                                    classadd === "A" && ans === "A"
                                      ? "correct"
                                      : ""
                                  } 
                                  ${
                                    classadd === "wrong" && ans === "A"
                                      ? "incorrect"
                                      : ""
                                  } 
                                  ${
                                    correctAnswer === "A" &&
                                    ans !== "A" &&
                                    classadd === "wrong"
                                      ? "highlight-correct"
                                      : ""
                                  }`}
                        onClick={() => {
                          if (!disabledcheck) {
                            setAns("A");
                            setCheck(true);
                          }
                        }}
                      >
                        <div className="option-letter">A</div>
                        <div className="option-text">
                          {HTMLReactParser(
                            questiondata.data.questions[current].A
                          )}
                        </div>
                      </div>

                      <div
                        className={`option ${ans === "B" ? "selected" : ""} 
                                  ${
                                    classadd === "B" && ans === "B"
                                      ? "correct"
                                      : ""
                                  } 
                                  ${
                                    classadd === "wrong" && ans === "B"
                                      ? "incorrect"
                                      : ""
                                  } 
                                  ${
                                    correctAnswer === "B" &&
                                    ans !== "B" &&
                                    classadd === "wrong"
                                      ? "highlight-correct"
                                      : ""
                                  }`}
                        onClick={() => {
                          if (!disabledcheck) {
                            setAns("B");
                            setCheck(true);
                          }
                        }}
                      >
                        <div className="option-letter">B</div>
                        <div className="option-text">
                          {HTMLReactParser(
                            questiondata.data.questions[current].B
                          )}
                        </div>
                      </div>

                      <div
                        className={`option ${ans === "C" ? "selected" : ""} 
                                  ${
                                    classadd === "C" && ans === "C"
                                      ? "correct"
                                      : ""
                                  } 
                                  ${
                                    classadd === "wrong" && ans === "C"
                                      ? "incorrect"
                                      : ""
                                  } 
                                  ${
                                    correctAnswer === "C" &&
                                    ans !== "C" &&
                                    classadd === "wrong"
                                      ? "highlight-correct"
                                      : ""
                                  }`}
                        onClick={() => {
                          if (!disabledcheck) {
                            setAns("C");
                            setCheck(true);
                          }
                        }}
                      >
                        <div className="option-letter">C</div>
                        <div className="option-text">
                          {HTMLReactParser(
                            questiondata.data.questions[current].C
                          )}
                        </div>
                      </div>

                      <div
                        className={`option ${ans === "D" ? "selected" : ""} 
                                  ${
                                    classadd === "D" && ans === "D"
                                      ? "correct"
                                      : ""
                                  } 
                                  ${
                                    classadd === "wrong" && ans === "D"
                                      ? "incorrect"
                                      : ""
                                  } 
                                  ${
                                    correctAnswer === "D" &&
                                    ans !== "D" &&
                                    classadd === "wrong"
                                      ? "highlight-correct"
                                      : ""
                                  }`}
                        onClick={() => {
                          if (!disabledcheck) {
                            setAns("D");
                            setCheck(true);
                          }
                        }}
                      >
                        <div className="option-letter">D</div>
                        <div className="option-text">
                          {HTMLReactParser(
                            questiondata.data.questions[current].D
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="quiz_main_bottom_content mt-30">
                      <p>
                        Note: Select your answer and click the{" "}
                        <span>Check</span> button. After checking, click
                        Continue for the next question.
                      </p>
                    </div>
                  </div>

                  <div className="quiz-footer">
                    {correct || (
                      <>
                        {disabledcheck === false && (
                          <>
                            {check === true ? (
                              <button
                                onClick={saveHandler}
                                className={`next-btn ${ans ? "active" : ""}`}
                                disabled={!ans}
                              >
                                Check
                              </button>
                            ) : (
                              <button
                                className={`next-btn ${ans ? "active" : ""}`}
                                onClick={saveHandler}
                                disabled={!ans}
                              >
                                Check
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className="quiz_bottom_right">
                      <p>Save time with our</p>
                      <Button
                        variant="link"
                        className="keyboard-shortcuts-btn"
                        onClick={handleShow}
                      >
                        Keyboard Shortcuts
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Keyboard Shortcuts Modal */}
        <Modal
          className="Keyboard-Shortcuts"
          size="lg"
          centered
          show={showh}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pb-120">
            <h4 className="mb-30">Keyboard Shortcuts</h4>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Submit Answer or Continue</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>ENTER</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Select Multiple Choice Answer</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>a</p>
                <p>b</p>
                <p>c</p>
                <p>d</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Reset Blanks Exercise </p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>R</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Footer />

        {/* Display the Quizpopup component when model is true */}
        {model === true ? (
          <Quizpopup photo={tempdata[1]} hide={() => setModel(false)} />
        ) : null}
      </div>

      {/* 3D effect on quiz container */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          document.addEventListener('mousemove', (e) => {
            const quizContainer = document.querySelector('.quiz-container.with-3d-effect');
            if (quizContainer) {
              const rect = quizContainer.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const y = e.clientY - rect.top;
              
              const xRotation = ((y - rect.height / 2) / 25) * -1;
              const yRotation = (x - rect.width / 2) / 25;
              
              quizContainer.style.transform = 
                \`perspective(1000px) rotateX(\${xRotation}deg) rotateY(\${yRotation}deg) translateZ(10px)\`;
            }
          });
          
          document.addEventListener('mouseleave', () => {
            const quizContainer = document.querySelector('.quiz-container.with-3d-effect');
            if (quizContainer) {
              quizContainer.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) translateZ(0)';
            }
          });
        `,
        }}
      />
    </>
  );
};

export default Box;
