import React, { useState, useEffect } from "react";
import "./ap.css";
// bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// page
import Header from "../../layout/header/Header";
import Top_header from "./Top_header";
import Footer from "../../layout/footer/Footer";
import BGRight1 from "../../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../../img/Bg Right 2.png"; // Import the background image
import Loader from "../../loader/Loader";
import { useGetAllsubjectQuery } from "../../services/aplms";
import Coursecard from "./Coursecard";
// pakege
import { Link, useHistory } from "react-router-dom";

const Ap = (props) => {
  const couresdata = useGetAllsubjectQuery(props.match.params.slug);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null) {
    history.push("/comingsoonpage");
    return null;
  }
  if (couresdata.isError) {
    history.push("/404");
    return null;
  }

  return (
    <section className="ap-section">

<img
                src={BGRight1}
                alt="Splatter"
                className="splatter-image-right-1"
              />
              <img
                src={BGRight2}
                alt="Splatter"
                className="splatter-image-right-2"
              />
      <div className="top-header ap-mobile-view-header">
        <Header />
        <Top_header propsintroData={props.match.params.slug} />
      </div>
      <section className="ap-main-content">
        {couresdata.data.map((subject) => {
          return (
            <Container>
    

              <h4 className="top-title">{subject.name}</h4>

              {isMobile ? (
                // If mobile, use a <Col> wrapper for better spacing
                <Col>
                  <Coursecard propsData={subject.slug} />
                </Col>
              ) : (
                // Otherwise, use a <Row> wrapper
                <Row>
                  <Coursecard propsData={subject.slug} />
                </Row>
              )}
            </Container>
          );
        })}
      </section>
      <Footer />
    </section>
  );
};

export default Ap;
