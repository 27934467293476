import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Error from "../../errordata/Error";
import Loader from "../../loader/Loader";
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "./Payment.css";
import { Modal, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import BGRight1 from "../../img/Bg Right 1.png";
import BGRight2 from "../../img/Bg Right 2.png";
import { useGetMonthplansQuery } from "../../services/aplms";
import { post } from "../../services/CallAPi";
import { useGetPaymentstatusQuery } from "../../services/aplms";
import { usePromoSubscriptionMutation } from "../../services/aplms";

const Payment = () => {
  const history = useHistory();

  const token = localStorage.getItem("token");

  // 1) We'll track how many months the user wants (intervalCount).
  //    The API query might use that to fetch or filter data.
  const [intervalCount, setIntervalCount] = useState(1);

  // 2) We'll store the ID of whichever plan the user selected
  //    (so we know which box is "selected").

  const [selectedPlans, setSelectedPlans] = useState([]);

  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [promoCode, setPromoCode] = useState("");

  const [alertMessage, setAlertMessage] = useState("");

  const [promoSuccess, setPromoSuccess] = useState(false);

  const [promoSubscription, { isLoading: isPromoLoading, error: promoError }] =
    usePromoSubscriptionMutation();

  // 3) Use the RTK Query hook to fetch plan data.
  //    If your endpoint doesn't take `intervalCount` as a param, remove it.
  const {
    data: apiResponse,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    refetch,
  } = useGetMonthplansQuery(intervalCount, {
    refetchOnMountOrArgChange: true,
  });

  // 5) If successful, pull out the plan array:
  //    `data.data` based on your logs: plan.data?.data => apiResponse.data
  const allPlans = apiResponse?.data || [];

  const disableSelection = isFetching;

  const { data: paymentData } = useGetPaymentstatusQuery(token);

  // 6) Group by curriculum name

  // 10.5) Get full plan objects for everything in selectedPlans
  const selectedPlanObjects = allPlans.filter((plan) =>
    selectedPlans.includes(plan.id)
  );

  const totalPrice = selectedPlanObjects.reduce(
    (sum, plan) => sum + parseFloat(plan.price),
    0
  );

  // 4)  handle loading/error states
  if (!apiResponse) {
    return <Loader />;
  }
  if (isError) {
    return <Error />;
  }

  const groupedByCurriculum = allPlans.reduce((acc, item) => {
    const key = item.curriculaname || "Other"; // fallback if null
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
  }, {});

  // 7) Sort the curriculum keys, for consistent ordering
  const sortedCurricula = Object.keys(groupedByCurriculum).sort();

  // 8) Let the user pick from preset intervals (1,3,6,12).
  //    If you have a dynamic approach, adapt as needed.
  const presetIntervals = [1, 3, 6, 12];

  // 9) Handler for changing the intervalCount via radio buttons or any UI
  const handleIntervalSelect = (count) => {
    setIntervalCount(count);
    setSelectedPlans([]); // Clear the cart whenever interval changes
  };

  // 10) Handler for selecting a specific plan card
  const handlePlanSelect = (planId) => {
    if (paymentData?.plantable?.some((ownedPlan) => ownedPlan.id === planId)) {
      setAlertMessage("You already own this plan!");
      setShowAlert(true);
      return;
    }

    setSelectedPlans((prev) => {
      if (prev.includes(planId)) {
        // If already selected, remove it
        return prev.filter((id) => id !== planId);
      } else {
        // Otherwise, add it
        return [...prev, planId];
      }
    });
  };

  // 11) On "Get started" => push user to /Checkout with the chosen plan info
  //     Look up the chosen plan data in allPlans
  const handleClick = async () => {
    setIsProcessingCheckout(true);

    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
      return;
    }

    // Helper to send checkout payload and redirect to Stripe
    const proceedToCheckout = async () => {
      if (selectedPlans.length === 0) {
        setAlertMessage("No plans selected!");
        setShowAlert(true);
        setIsProcessingCheckout(false);
        return;
      }
      const allSelectedPlanIds = selectedPlans.map((id) => ({ plan_id: id }));
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const { data } = await post(
          "student/checkout",
          { plans: allSelectedPlanIds },
          config
        );
        if (!data || !data.url) {
          console.error("No 'url' returned from server:", data);
          setIsProcessingCheckout(false);
          return;
        }
        window.location.replace(data.url);
      } catch (error) {
        setIsProcessingCheckout(false);
        console.error("Error during checkout:", error);
      }
    };

    // If promo code is provided, attempt to use it
    if (promoCode.trim() !== "") {
      if (selectedPlans.length === 0) {
        setAlertMessage("Please select a plan before applying a promo code!");
        setShowAlert(true);
        setIsProcessingCheckout(false);
        return;
      }
      const planID = selectedPlans[0];
      try {
        const promoResponse = await promoSubscription({
          promocode: promoCode.trim(),
          token,
          planID,
        }).unwrap();

       

        // if (promoResponse && promoResponse.invoice_url) {
        //   // Promo code is valid: show alert and redirect
        //   setAlertMessage(
        //     "Success! " + promoResponse.message + " Redirecting to home page..."
        //   );
        //   setShowAlert(true);

        //   return; // Do NOT proceed to checkout
        // } else {
        //   // Promo code is invalid: show alert, do NOT proceed
        //   setAlertMessage("Invalid promo code.");
        //   setShowAlert(true);
        //   setIsProcessingCheckout(false);
        //   return;
        // }

        if (promoResponse && promoResponse.status === "200") {
          setIsProcessingCheckout(false);
          setAlertMessage(
              promoResponse.message 
          );
          setShowAlert(true);
          setPromoSuccess(true);

          return;
        } else {
          setAlertMessage("Invalid promo code.");
          setShowAlert(true);
          setIsProcessingCheckout(false);
          return;
        }
      } catch (error) {
        console.error("Promo subscription error:", error);
        // Server error applying code: show alert, do NOT proceed
        setAlertMessage("Error applying promo code.");
        setShowAlert(true);
        setIsProcessingCheckout(false);
        return;
      }
    } else {
      // No promo code provided; proceed immediately.
      proceedToCheckout();
    }
  };

  if (isProcessingCheckout) {
    return (
      <div className="loader-centered">
        <Loader />
      </div>
    );
  }

  return (
    <section className="payment-page">
      <Header />
      <img src={BGRight1} alt="Splatter" className="splatter-image-right-1" />
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />

      <Modal show={showAlert} onHide={() => setShowAlert(false)} centered>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "2px solid #5b9311",
            backgroundColor: "#f8f9fa",
          }}
        >
          <Modal.Title style={{ color: "#5b9311", fontWeight: "bold" }}>
            Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1rem", color: "#333" }}>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button
            onClick={() => {
              // Always close the alert
              setShowAlert(false);
              // If the promo was successful, navigate to the profile
              if (promoSuccess) {
                window.location.href = "/";
              }
            }}
            style={{
              backgroundColor: "#5b9311",
              borderColor: "#5b9311",
              fontWeight: "bold",
              padding: "0.5rem 1.5rem",
              borderRadius: "4px",
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Container className="pb-120">
        {/* Title / Intro */}
        <Row>
          <Col sm={12}>
            <div className="payment-page-heading text-center">
              <h2 className="pricing-header">Pricing</h2>
              <p>
                Thank you for supporting. <br />
                We are working hard to build amazing resources for students
                worldwide.
              </p>
            </div>
          </Col>
        </Row>

        {isProcessingCheckout && (
          <div className="checkout-overlay">
            <Loader />
            <p>Redirecting to payment...</p>
          </div>
        )}

        {/* Interval (Months) Radio Group */}
        <div className="my-green-form-container">
          <ul className="my-green-form-list">
            {presetIntervals.map((count) => (
              <li key={count}>
                <input
                  type="radio"
                  name="intervalGroup"
                  id={`radio-interval-${count}`}
                  checked={intervalCount === count}
                  onChange={() => handleIntervalSelect(count)}
                />
                <label
                  htmlFor={`radio-interval-${count}`}
                  className="interval-label"
                >
                  {count} Month{count > 1 ? "s" : ""}
                  {count === 12 && (
                    <span className="discount-badge">50% OFF</span>
                  )}
                </label>
              </li>
            ))}
          </ul>
        </div>

        {/* Render each curriculum row */}
        {sortedCurricula.map((curriculum) => {
          const curriculumPlans = groupedByCurriculum[curriculum];

          return (
            <div key={curriculum} style={{ marginTop: "2rem" }}>
              {/* Curriculum header */}
              <Row>
                <Col sm={12}>
                  <h3 className="curric-title" style={{ marginBottom: "1rem" }}>
                    {curriculum}
                  </h3>
                </Col>
              </Row>

              {/* Each plan => payment card box */}
              <Row className="pb-30">
                {curriculumPlans.map((planObj) => {
                  // Multiply base price by intervalCount
                  const numericPrice = parseFloat(planObj.price) || 0;
                  const displayPrice = numericPrice;
                  const isSelected = selectedPlans.includes(planObj.id);

                  return (
                    <Col sm={3} key={planObj.id}>
                      <div
                        className={`payment_card_box ${
                          isSelected ? "selected-box" : ""
                        }`}
                        onClick={
                          !disableSelection
                            ? () => handlePlanSelect(planObj.id)
                            : undefined
                        }
                      >
                        {/* If you have a "Most Popular" logic, apply it here. */}
                        {/* Plan Name / Price */}
                        <div className="payment_card_price">
                          {intervalCount === 12 ? (
                            <p>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  marginRight: "8px",
                                  color: "darkgray",
                                }}
                              >
                                $199
                              </span>
                              <span style={{ color: "red" }}>
                                ${displayPrice.toFixed(2)}
                              </span>
                            </p>
                          ) : (
                            <p>${displayPrice.toFixed(2)}</p>
                          )}
                        </div>
                        <h4 className="payment_card_title">
                          {planObj.couresname}
                        </h4>

                        <div className="payment_card_box_body ">
                          <h5>{planObj.name} (Recurring Payment)</h5>
                          <ul>
                            {/* Available feature => Green checkmark */}
                            <li>
                              <FaCheck style={{ color: "#28a745" }} />
                              <span>Question Bank</span>
                            </li>

                            <li>
                              <FaCheck style={{ color: "#28a745" }} />
                              <span>Past Papers</span>
                            </li>

                            {/* Coming soon => Light/gray checkmark */}
                            <li>
                              <FaCheck style={{ color: "#ccc" }} />
                              <span>Practice Exam (Coming soon)</span>
                            </li>

                            <li>
                              <FaCheck style={{ color: "#ccc" }} />
                              <span>Key Concepts (Coming soon)</span>
                            </li>
                          </ul>
                        </div>

                        <button
                          className={`checkbox_button ${
                            isSelected ? "selected" : ""
                          }`}
                          onClick={(e) => {
                            if (disableSelection) return; // do nothing if disabled
                            e.stopPropagation(); // Avoid double event if you want
                            handlePlanSelect(planObj.id);
                          }}
                        >
                          {isSelected && (
                            <FaCheck
                              style={{ marginRight: "8px", color: "#000" }}
                            />
                          )}
                          <span>{isSelected ? "Selected" : "Subscribe"}</span>
                        </button>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}

        {/* CART SECTION
    Only show this if at least one plan is selected
*/}
        {selectedPlans.length > 0 && (
          <div className="selected-cart-container">
            <Container>
              <Row className="align-items-center">
                <Col xs={12} md={8}>
                  <h5>Your Selected Plans:</h5>
                  <ul className="cart-items-list">
                    {selectedPlanObjects.map((plan) => (
                      <li key={plan.id} className="cart-item">
                        <span className="cart-plan-title">
                          {plan.couresname}
                        </span>
                        <span className="cart-plan-price">
                          ${parseFloat(plan.price).toFixed(2)}
                        </span>
                      </li>
                    ))}
                  </ul>

                  {/* Total Price under the list */}
                  <h5 className="cart-total" style={{ marginTop: "1rem" }}>
                    Total: ${totalPrice.toFixed(2)}
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        <Container
          className="promo-code-container"
          style={{ marginTop: "1rem" }}
        >
          <Row>
            <Col xs={12} md={8}>
              <Form.Group controlId="promoCode">
                <Form.Label>Promo Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter promo code"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  style={{
                    border: "1px solid black",
                    position: "relative",
                    zIndex: 20,
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>

        <Button
          disabled={disableSelection}
          onClick={!disableSelection ? handleClick : undefined}
          className="bottom-button-more"
        >
          {selectedPlans.length > 0
            ? `Get started ($${totalPrice.toFixed(2)})`
            : "Get started"}
        </Button>
      </Container>
      <Footer />
    </section>
  );
};

export default Payment;
