import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
import Right_arror from "../img/icon/right-arror.png";
import Right_arror_w from "../img/icon/Expand_right_light_w.png";
import { useGetAlltopicQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import "../key-concept/KeyTopic.css"; // Make sure to import the same CSS

const Questionbanktopics = (props) => {
  const slug = props.propsdata;
  const topicdata = useGetAlltopicQuery(slug);

  if (topicdata.isLoading) return <Loader />;
  
  if (!topicdata.data || topicdata.data.length === 0)
    return (
      <div className="coming-soon glass-card">
        <h1>Coming Soon</h1>
      </div>
    );

  if (topicdata.isError)
    return <h1>An error occurred {topicdata.error.error}</h1>;

  return (
    <div className="topic-container">
      <Row>
        {topicdata.data.map((topic) => (
          <Col sm={4} key={topic.id || topic.slug} className="topic-col">
            <NavLink 
              to={{
                pathname: `/Question-Bank-Unit/${slug}`,
                state: { selectedTopicSlug: topic.slug }, // Preserve the state passing
              }}
              className="topic-card glass-card"
            >
              <div className="topic-content">
                <h4>{topic.name}</h4>
                <div className="topic-arrow">
                  <img src={Right_arror} className="d" alt="arrow" />
                </div>
              </div>
              <div className="hover-indicator"></div>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Questionbanktopics;