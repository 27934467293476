import React, { useState, useEffect } from "react";
import Isotope from "isotope-layout";
import Filter from "./Filter";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Header from "../layout/header/Header";
import { useDispatch } from "react-redux";

import img_1 from "../img/paper-photo.png";
import img_3 from "../img/for.png";
import BGMiddle from "../img/BG Middle.png";

import { NavLink, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Changepassword from "./ChangePassword";
import BillingTab from "./BillingTab";

import {
  useLogoutUserMutation,
  useGetLoggedUserQuery,
} from "../services/aplms";

import {
  getToken,
  removeToken,
  removeUserinfo,
} from "../services/LocalStorageService";
import { setUserInfo, unsetUserInfo } from "../features/userSlice";

// 1) Import the updateProfile mutation
import { useUpdateProfileMutation } from "../services/aplms"; // <--- Make sure it's correctly exported in aplms
import { useGetPaymentstatusQuery } from "../services/aplms";
import Footer from "../layout/footer/Footer";

const View = ({ blogs, handleBlogs, props }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // Get the token from the URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // Save token to localStorage
      localStorage.setItem("token", token);

      // Optional: Remove token from URL after saving
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  // 2) Local state for storing and editing profile fields
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    school: "",
    plane_status: "",
  });

  const [originalUserData, setOriginalUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    school: "",
    plane_status: "",
  });

  // 3) Pull token and user data

  const token = getToken();

  const { data, isSuccess, refetch } = useGetLoggedUserQuery(token);

  // 4) Setup the updateProfile mutation
  const [updateProfile] = useUpdateProfileMutation();
  const [filterValue, setFilterValue] = useState("Profile");

  const [successMessage, setSuccessMessage] = useState("");
  // 5) Additional local states for password toggles (as in your original code)

  const { data: paymentData, isSuccess: isPaymentSuccess } =
    useGetPaymentstatusQuery(token);

  // 6) Load existing user info into userData (so the form is pre-filled)
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }
    if (data && isSuccess && data.user) {
      const userDataFromApi = {
        first_name: data.user.first_name || "",
        last_name: data.user.last_name || "",
        email: data.user.email || "",
        phone: data.user.phone || "",
        country: data.user.country || "",
        school: data.user.school || "",
        plane_status: data.user.status || "",
      };

      setUserData(userDataFromApi);

      // Store original data for reset functionality
      setOriginalUserData(userDataFromApi);

      // Also store user data in Redux (as your code did)
      dispatch(
        setUserInfo({
          email: data.user.email,
          name: data.user.first_name, // or combine first + last if you prefer
          phone: data.user.phone,
          country: data.user.country,
          school: data.user.school,
          plane_status: data.user.status,
        })
      );
    }
  }, [data, isSuccess, dispatch, history]);

  // 7) Handler for form inputs (except phone & country)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  // 8) Specialized handlers (PhoneInput & FlagsSelect)
  const handlePhoneChange = (val) => {
    setUserData((prev) => ({ ...prev, phone: val }));
  };

  const handleSelectCountry = (code) => {
    setUserData((prev) => ({ ...prev, country: code }));
  };

  // 9) "Save Changes" button => calls updateProfile
  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        actualData: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
          country: userData.country,
          school: userData.school,
        },
        token: token,
      };

      // Log the payload being sent

      // Call the mutation with the payload
      const response = await updateProfile(payload);

      if (response.data) {
        const newProfile = await refetch();

        setOriginalUserData(userData);

        setSuccessMessage("Changes successfully made!");
        setTimeout(() => {
          setSuccessMessage("");
        }, 6000);
      } else if (response.error) {
        console.error("Error from server:", response.error);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  // --- The rest is your existing code for Isotope, modals, other tabs, etc. ---

  const handleCancel = () => {
    // Reset form to original values
    setUserData(originalUserData);
    
    // Show message to user
    setSuccessMessage("Changes discarded");
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
  };


  const [selected, setSelected] = useState("Profile");
  const [iso, setIso] = useState(null);

  useEffect(() => {
    setIso(
      new Isotope(".grid", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows",
        filter: ".Profile", // only show Profile items at first
      })
    );
  }, []);

  useEffect(() => {
    if (iso) {
      iso.arrange({
        filter: filterValue === "All" ? "*" : `.${filterValue}`,
      });
    }
  }, [filterValue, iso]);

  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);

  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const fullimageClose = () => setShow(false);
  const fullimageShow = () => setShow(true);

  return (
    <div className="Myprofile-section">
      <>
        <div className="myprofile-background">
          <Header />
          <div style={{ height: "60px" }}></div> {/* Spacer */}
          <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle"
          />
          <div className="glass-background">
            <Container>
              <section className="ap desktop-view">
                <Container>
                  <Row className="pb-60">
                    <Col sm={12}>
                      <div className="myprofile-heading pt-30">
                        <h2>My Profile</h2>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Row className="">
                <Col sm={12}>
                  <div className="filter_myprofile_top">
                    <Filter setFilterValue={setFilterValue} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="grid">
                    {/* PROFILE TAB */}
                    <div className="filter-item first Profile pb-60">
                      <div className="">
                        {/* Personal Info Header */}
                        <div className="myprofile-password pt-30">
                          <div className="text-start">
                            {successMessage && (
                              <div
                                className={`success-alert ${
                                  successMessage ? "show" : ""
                                }`}
                              >
                                {successMessage}
                              </div>
                            )}
                            <p className="text-start">Personal Info</p>
                            <p className="update-p-text">
                              Update your personal details here
                            </p>
                          </div>
                          <div className="myprofile-password-update">
                            <Button variant="Cancel" type="button" onClick={handleCancel}>
                              Cancel
                            </Button>
                            {/* On Save => handleSaveChanges */}
                            <Button
                              className="save-changes-btn"
                              variant="primary"
                              onClick={handleSaveChanges}
                            >
                              Save Changes
                            </Button>
                          </div>
                        </div>

                        {/* PROFILE FORM FIELDS */}
                        <Form /* If you'd like, you can add onSubmit={handleSaveChanges} */
                        >
                          <div className="myprofile_update_main pt-30">
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                First Name & Last Name
                              </Form.Label>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  placeholder="First Name"
                                  name="first_name"
                                  value={userData.first_name}
                                  onChange={handleInputChange}
                                />
                              </Col>
                              <Col sm={4}>
                                <Form.Control
                                  type="text"
                                  placeholder="Last Name"
                                  name="last_name"
                                  value={userData.last_name}
                                  onChange={handleInputChange}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                          <div className="myprofile_update_main pt-30">
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Email
                              </Form.Label>
                              <Col sm={8}>
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  name="email"
                                  value={userData.email}
                                  onChange={handleInputChange}
                                />
                              </Col>
                            </Form.Group>
                          </div>

                          <div className="myprofile_update_main pt-30">
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Phone
                              </Form.Label>
                              <Col sm={8}>
                                <PhoneInput
                                  className="form-control Country_code_input"
                                  international
                                  defaultCountry="RU"
                                  value={userData.phone}
                                  onChange={handlePhoneChange}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                          <div className="myprofile_update_main bt-border-none pt-30 pb-30">
                            <Form.Group as={Row} className="mb-3">
                              <Form.Label column sm={4}>
                                Country
                              </Form.Label>
                              <Col sm={8}>
                                <ReactFlagsSelect
                                  className="flags_select_input"
                                  selected={userData.country}
                                  onSelect={handleSelectCountry}
                                  searchable
                                  searchPlaceholder="Search for a country"
                                />
                              </Col>
                            </Form.Group>
                          </div>
                        </Form>
                      </div>
                      <div className="mobile-button-container">
                        <Button variant="Cancel" type="button">
                          Cancel
                        </Button>
                        <Button
                          className="save-changes-btn"
                          variant="primary"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>

                    <div className="filter-item Password pb-60">
                      <Changepassword />
                    </div>
                    <div className="filter-item Billing pb-60">
                      <BillingTab
                        paymentData={paymentData}
                        isPaymentSuccess={isPaymentSuccess}
                      />
                    </div>
                    <div className="filter-item Notifications pb-60">
                      <div className=" ">
                        <div className="myprofile-password pt-30 pb-30">
                          <p>Notifications & News Updates</p>
                          <div className="myprofile-password-update">
                            <Button variant="Cancel" type="Cancel">
                              Cancel
                            </Button>
                            <Button
                              className="save-changes-btn"
                              variant="primary"
                            >
                              Save Changes
                            </Button>
                          </div>
                        </div>
                        <div className="myprofile-password-from pt-30 pb-30">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`default-${type}`}>
                                <Form.Check
                                  className="checkbox_notifications"
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Email me about updates, changes and price discounts.`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                        <div className="myprofile-password-from pt-30 pb-30">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`default-${type}`}>
                                <Form.Check
                                  className="checkbox_notifications"
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Text me about updates, changes and price discounts.`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                        <div className="myprofile-password-from bt-border-none pt-30 pb-30">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`default-${type}`}>
                                <Form.Check
                                  className="checkbox_notifications"
                                  type={type}
                                  id={`default-${type}`}
                                  label={`Send me billing updates. `}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="mobile-button-container">
                        <Button variant="Cancel" type="button">
                          Cancel
                        </Button>
                        <Button
                          className="save-changes-btn"
                          variant="primary"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </div>

        {/* Full Image Popup */}
        <Modal
          {...props}
          size="xl"
          centered
          show={show}
          onHide={fullimageClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="full-img-popup" closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={img_1} alt="paper" />
          </Modal.Body>
        </Modal>

        {/* Another Modal */}
        <Modal
          className="Formula-popup"
          {...props}
          size="xl"
          centered
          show={showh}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="full-img-popup" closeButton></Modal.Header>
          <Modal.Body>
            <img src={img_3} alt="formula" />
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default View;
