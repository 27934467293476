import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useGetAllkeyconceptquestionQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import HTMLReactParser from "html-react-parser";
import MathJaxRenderer from "../MathJaxRenderer";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { AiOutlineExpand, AiOutlineCompress } from "react-icons/ai";
import "../questionbank/Unit.css";

// Import images
import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";
import FormulaIcon from "../img/FormulaIcon.png";
import MarkSchemeIcon from "../img/MarkSchemeIcon.png";
import SimilarQuestionIcon from "../img/SimilarQuestionIcon.png";
import VideoAnswerIcon from "../img/VideoAnswerIcon.png";
import BGRight2 from "../img/Bg Right 2.png";

const View = ({
  blogs,
  handleBlogs,
  filterValue,
  setFilterValue,
  props,
  propsData,
}) => {
  const history = useHistory();
  
  // RTK Query - fetch data using the original approach
  const questiondata = useGetAllkeyconceptquestionQuery(propsData);
  
  // State for UI from Unit component
  const [selectedDifficulty, setSelectedDifficulty] = useState("All");
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [visibleQuestionIndex, setVisibleQuestionIndex] = useState(0);

  // Modal states
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkSchemeHTML, setCurrentMarkSchemeHTML] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  
  // Fullscreen state
  const [isFullscreen, setIsFullscreen] = useState(false);
  
  // Scroll controls
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [activeScrollInterval, setActiveScrollInterval] = useState(null);
  
  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  
  // Store original indices in a ref instead of state to avoid re-renders
  const questionIndicesRef = React.useRef({});
  
  // Populate the indices when data changes
  useEffect(() => {
    if (questiondata.data) {
      const newIndices = {};
      questiondata.data.forEach((question, index) => {
        newIndices[question.id] = index;
      });
      questionIndicesRef.current = newIndices;
    }
  }, [questiondata.data]);
  
  // Create a filtered list of questions without modifying the original objects
  const filteredQuestions = questiondata.data ? questiondata.data.filter((question) => {
    if (selectedDifficulty === "All") return true;
    return question.status === selectedDifficulty;
  }) : [];
  
  // Scroll handlers
  useEffect(() => {
    const scrollContainer = document.getElementById("question-list-scroll");
    
    if (scrollContainer) {
      let lastScrollLeft = 0;
      
      const handleScroll = () => {
        const currentScrollLeft = scrollContainer.scrollLeft;
        const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
        
        setCanScrollLeft(currentScrollLeft > 10);
        setCanScrollRight(currentScrollLeft < maxScrollLeft - 10);
        
        lastScrollLeft = currentScrollLeft;
      };
      
      scrollContainer.addEventListener("scroll", handleScroll);
      
      // Initial check
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      setCanScrollRight(maxScrollLeft > 10);
      
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [filteredQuestions]);
  
  // Set up Intersection Observer for question visibility
  useEffect(() => {
    if (!filteredQuestions || filteredQuestions.length === 0) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.reduce((prev, current) => {
          return prev && prev.intersectionRatio > current.intersectionRatio
            ? prev
            : current;
        });
        
        if (visibleEntry && visibleEntry.intersectionRatio > 0.2) {
          const id = visibleEntry.target.id;
          const index = parseInt(id.split("-").pop());
          
          setVisibleQuestionIndex(index);
          setSelectedQuestionIndex(index);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5],
      }
    );
    
    filteredQuestions.forEach((_, index) => {
      const element = document.getElementById(`questions-container-${index}`);
      if (element) observer.observe(element);
    });
    
    return () => {
      filteredQuestions.forEach((_, index) => {
        const element = document.getElementById(`questions-container-${index}`);
        if (element) observer.unobserve(element);
      });
    };
  }, [filteredQuestions]);
  
  // Modal handlers
  const handleOpenQuestionModal = (question) => {
    setCurrentQuestion(question);
    setShowQuestionModal(true);
  };
  
  const handleOpenMarkSchemeModal = (htmlString) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setCurrentMarkSchemeHTML(htmlString);
    setShowMarkSchemeModal(true);
  };
  
  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
  };
  
  const handleOpenVideoModal = (videoURL) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setCurrentVideoURL(videoURL);
    setShowVideoModal(true);
  };
  
  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideoURL(null);
  };
  
  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };
  
  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };
  
  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
    const questionElement = document.getElementById(`questions-container-${index}`);
    if (questionElement) {
      questionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  
  // Helper function to convert YouTube URLs to embed URLs
  const getEmbedUrl = (url) => {
    if (!url) return "";
    try {
      const parsedUrl = new URL(url);
      if (
        parsedUrl.hostname.includes("youtube.com") &&
        parsedUrl.pathname === "/watch"
      ) {
        const videoId = parsedUrl.searchParams.get("v");
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
      if (parsedUrl.hostname === "youtu.be") {
        const videoId = parsedUrl.pathname.slice(1);
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
    return url;
  };
  
  // Fullscreen toggle
  const toggleFullscreen = (index) => {
    const container = document.getElementById(`questions-container-${index}`);
    if (container) {
      if (!document.fullscreenElement) {
        container.requestFullscreen().catch((err) => {
          console.error(`Error attempting to enable fullscreen: ${err.message}`);
        });
        setIsFullscreen(true);
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(`Error attempting to exit fullscreen: ${err.message}`);
        });
        setIsFullscreen(false);
      }
    }
  };
  
  // Loading state
  if (questiondata.isLoading) {
    return <Loader />;
  }
  
  // Error state
  if (questiondata.isError) {
    return <h1>An error occurred {questiondata.error.error}</h1>;
  }
  
  // Empty data state
  if (!questiondata.data || questiondata.data.length === 0) {
    return <Error />;
  }
  
  return (
    <div className="questionbank-unit">
      {/* Top Section & Header */}
      <div className="top-header-quetion">
        <Header />
        <section className="ap">
          <div className="my-container">
            <div className="my-row pb-60">
              <div className="my-col-sm-12">
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + questiondata.data[0].curriculaslug}>
                        {questiondata.data[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + questiondata.data[0].curriculaslug}>
                        {questiondata.data[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(2)}>
                        {questiondata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {questiondata.data[0].unitname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>

              <div className="my-col-sm-12">
                <div className="qb-heading">
                  <h2>{questiondata.data[0].couresname} - Key Concept</h2>
                  <div className="my-row">
                    <div className="my-col-sm-8">
                      <MathJaxRenderer>
                        <p className="course-description">
                          {HTMLReactParser(questiondata.data[0].paperdescription)}
                        </p>
                      </MathJaxRenderer>
                    </div>
                    <div className="my-col-sm-4">
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" alt="Quiz Icon 1" />
                        <img src={qu_top_w} alt="Quiz Icon 2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      {/* Main Section */}
      <section className="unit-page pt-60 pb-120">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <div className="my-row">
          {/* Question navigation sidebar */}
          <div className="my-col-sm-3 sticky-column">
            <h3 className="unit-title">Key Concept Questions</h3>
            {questiondata.data.map((question, index) => (
              <Button
                key={index}
                onClick={() => {
                  setSelectedQuestionIndex(index);
                  setVisibleQuestionIndex(index);
                  setTimeout(() => {
                    const targetQuestion = document.getElementById(
                      `questions-container-${index}`
                    );
                    if (targetQuestion) {
                      const rect = targetQuestion.getBoundingClientRect();
                      const absoluteY = window.scrollY + rect.top - 200;
                      window.scrollTo({ top: absoluteY, behavior: "smooth" });
                    }
                  }, 300);
                }}
                variant="outline-primary"
                className={`topic-button ${
                  selectedQuestionIndex === index ? "selected" : ""
                }`}
              >
                <span className="topic-button-text">Question {index + 1}</span>
                <FaChevronRight className="topic-button-icon" />
              </Button>
            ))}
          </div>
          
          {/* Questions display area */}
          <div className="my-col-sm-8">
            {/* Difficulty filters */}
            <div className="my-col-sm-11">
              <div className="difficulty-filters">
                <Button
                  onClick={() => setSelectedDifficulty("Easy")}
                  className={selectedDifficulty === "Easy" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={EasyIcon} alt="Easy" />
                  Easy
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Medium")}
                  className={selectedDifficulty === "Medium" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={MediumIcon} alt="Medium" />
                  Medium
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Hard")}
                  className={selectedDifficulty === "Hard" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={HardIcon} alt="Hard" />
                  Hard
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("All")}
                  className={selectedDifficulty === "All" ? "active" : ""}
                  variant="outline-primary"
                >
                  All
                </Button>
              </div>
            </div>
            
            {/* Questions list and display */}
            {filteredQuestions && filteredQuestions.length > 0 ? (
              <div>
                {/* Horizontal question navigation */}
                <div className="question-list-scroll-container" style={{ marginBottom: "20px" }}>
                  {canScrollLeft && (
                    <div
                      className="scroll-arrow-indicator scroll-arrow-left"
                      onMouseDown={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }
                        
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            scrollContainer.scrollBy({
                              left: -10,
                              behavior: "auto",
                            });
                            
                            if (scrollContainer.scrollLeft <= 0) {
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);
                          
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onMouseUp={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onMouseLeave={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                    >
                      <FaChevronLeft />
                    </div>
                  )}
                  
                  <div
                    className="question-list-scroll"
                    id="question-list-scroll"
                  >
                    {filteredQuestions.map((question, index) => (
                      <div
                        key={index}
                        onClick={() => handleQuestionClick(index)}
                        className={`question-list-item ${
                          visibleQuestionIndex === index ||
                          selectedQuestionIndex === index
                            ? "active"
                            : ""
                        }`}
                      >
                        Question {questionIndicesRef.current[question.id] !== undefined ? questionIndicesRef.current[question.id] + 1 : index + 1}
                      </div>
                    ))}
                  </div>
                  
                  {canScrollRight && (
                    <div
                      className="scroll-arrow-indicator scroll-arrow-right"
                      onMouseDown={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }
                        
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            const maxScrollLeft =
                              scrollContainer.scrollWidth -
                              scrollContainer.clientWidth;
                              
                            if (scrollContainer.scrollLeft < maxScrollLeft) {
                              scrollContainer.scrollBy({
                                left: 10,
                                behavior: "auto",
                              });
                            } else {
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);
                          
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onMouseUp={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onMouseLeave={() => {
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                    >
                      <FaChevronRight />
                    </div>
                  )}
                </div>
                
                {/* Render each question */}
                {filteredQuestions.map((question, index) => (
                  <div
                    key={question.originalIndex}
                    className="questions-container"
                    id={`questions-container-${index}`}
                    onClick={() => {
                      if (window.innerWidth <= 1025) {
                        handleOpenQuestionModal(question);
                      }
                    }}
                  >
                    {/* Question header */}
                    <div className="title-container">
                      <h3 className="h3-title">
                        Question {questionIndicesRef.current[question.id] !== undefined ? questionIndicesRef.current[question.id] + 1 : index + 1}
                      </h3>
                      
                      {/* Difficulty icon */}
                      {question.status === "Easy" && (
                        <img
                          src={EasyIcon}
                          alt="Easy Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Medium" && (
                        <img
                          src={MediumIcon}
                          alt="Medium Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Hard" && (
                        <img
                          src={HardIcon}
                          alt="Hard Icon"
                          className="status-icon"
                        />
                      )}
                    </div>
                    
                    {/* Formula button */}
                    {question.formula && (
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenFormulaModal(question.formula);
                        }}
                        className="formulaButton"
                      >
                        <img
                          src={FormulaIcon}
                          alt="Formulas Icon"
                          className="formulaIcon"
                        />
                        <span className="formulatext">Formulas</span>
                      </Button>
                    )}
                    
                    {/* Question content */}
                    <div className="question-photo">
                      <MathJaxRenderer>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.description.replace(
                              /<img /g,
                              '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                            ),
                          }}
                        />
                      </MathJaxRenderer>
                    </div>
                    
                    {/* Action buttons */}
                    <div className="additional-buttons">
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenMarkSchemeModal(question.photo);
                        }}
                        className="markscheme-button"
                      >
                        <img
                          src={MarkSchemeIcon}
                          alt="Mark Scheme Icon"
                          className="markscheme-icon"
                        />
                        Mark Scheme
                      </Button>
                      
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenVideoModal(question.video);
                        }}
                        className="videoanswer-button"
                      >
                        <img
                          src={VideoAnswerIcon}
                          alt="Video Answer Icon"
                          className="videoanswer-icon"
                        />
                        Video Answer
                      </Button>
                      
             
                      
                      <Button
                        variant="link"
                        onClick={() => toggleFullscreen(index)}
                        className="fullscreen-button"
                        title={
                          isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"
                        }
                      >
                        {isFullscreen ? (
                          <AiOutlineCompress size={24} />
                        ) : (
                          <AiOutlineExpand size={24} />
                        )}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </section>
      
      {/* Mobile question modal */}
      <Modal
        show={showQuestionModal}
        onHide={() => setShowQuestionModal(false)}
        fullscreen={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Question {currentQuestion && questionIndicesRef.current[currentQuestion.id] !== undefined 
              ? questionIndicesRef.current[currentQuestion.id] + 1 
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentQuestion && (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.description.replace(
                    /<img /g,
                    '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                  ),
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      
      {/* Formula modal */}
      <Modal
        show={showFormulaModal}
        onHide={handleCloseFormulaModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFormulaImage && (
            <img
              src={currentFormulaImage}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
              alt="Formula"
            />
          )}
        </Modal.Body>
      </Modal>
      
      {/* Video modal */}
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVideoURL ? (
            <div className="video-container">
              <iframe
                src={getEmbedUrl(currentVideoURL)}
                style={{ width: "100%", height: "400px" }}
                title="Video Answer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </Modal.Body>
      </Modal>
      
      {/* Mark scheme modal */}
      <Modal
        show={showMarkSchemeModal}
        onHide={handleCloseMarkSchemeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentMarkSchemeHTML ? (
            <MathJaxRenderer>
              {HTMLReactParser(currentMarkSchemeHTML, {
                replace: (domNode, index) => {
                  if (domNode.type === "tag" && domNode.name === "img") {
                    return (
                      <img
                        key={index}
                        src={domNode.attribs.src}
                        alt={
                          domNode.attribs.alt ||
                          `Mark Scheme image ${index + 1}`
                        }
                        style={{
                          width: "100%",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                    );
                  }
                },
              })}
            </MathJaxRenderer>
          ) : (
            <p>No Mark Scheme available.</p>
          )}
        </Modal.Body>
      </Modal>
      
      <Footer />
    </div>
  );
};

export default View;