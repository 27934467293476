import React, { useState, useEffect, useRef } from "react";
import {
  useAddFeedbackMutation,
  useQuestionStatusMutation,
  useToggleResolvedMutation,
  useReplyToCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
} from "../services/aplms";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  FaRegCommentDots,
  FaComment,
  FaPaperPlane,
  FaThumbsUp,
  FaThumbsDown,
  FaCheckCircle,
  FaStar,
  FaRegStar,
  FaTrash, // Trash/bin icon
  FaReply, // Reply icon
  FaEdit, // Edit icon
} from "react-icons/fa";
import "./QuestionComments.css";
import AlertModal, { ALERT_TYPES } from "../AlertModal";

function CommentItem({
  comment,
  questionSlug,
  token,
  refetchQuestionStatus,
  indent = 0, // how many pixels to indent nested replies
}) {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState("");

  // We'll get the logged-in user's ID from Redux:
  const userId = useSelector((state) => localStorage.getItem("id"));

  const [replyToComment, { isLoading: isReplying }] =
    useReplyToCommentMutation();
  const [deleteComment, { isLoading: isDeleting }] = useDeleteCommentMutation();
  const [editComment, { isLoading: isEditingComment }] =
    useEditCommentMutation();

  // Fix potential data type issues - convert IDs to strings for comparison
  const userIdStr = String(userId);
  const commentUserIdStr = String(comment.userId);

  // "Can user delete or edit this comment?"
  // Both deletion and editing - user must own the comment
  const canDelete = commentUserIdStr === userIdStr;
  const canEdit = commentUserIdStr === userIdStr;

  const handleDelete = async () => {
    if (!token) {
      AlertModal.api.showAlert("You need to be logged in to delete comments.", {
        type: ALERT_TYPES.WARNING,
      });
      return;
    }

    if (commentUserIdStr !== userIdStr) {
      AlertModal.api.showAlert("You can only delete your own comments.", {
        type: ALERT_TYPES.ERROR,
      });
      return;
    }

    // Instead of window.confirm, use AlertModal.api.showConfirm
    AlertModal.api.showConfirm(
      "Are you sure you want to delete this comment? All replies will also be deleted.",
      {
        type: ALERT_TYPES.WARNING,
        confirmText: "Delete",
        cancelText: "Cancel",
        onConfirm: async () => {
          try {
            console.log("Attempting to delete comment:", comment.id);
            const response = await deleteComment({
              commentId: comment.id,
              token,
            }).unwrap();
            console.log("Delete response:", response);

            // Re-fetch after deleting
            refetchQuestionStatus();
          } catch (err) {
            console.error("Failed to delete comment:", err);
            if (err.data && err.data.message) {
              AlertModal.api.showAlert(err.data.message, {
                type: ALERT_TYPES.ERROR,
              });
            } else {
              AlertModal.api.showAlert(
                "Unable to delete comment: " + (err.message || "Unknown error"),
                {
                  type: ALERT_TYPES.ERROR,
                }
              );
            }
          }
        },
      }
    );
  };

  // Start editing a comment
  const handleStartEdit = () => {
    setIsEditing(true);
    setEditText(comment.text);
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditText("");
  };

  // Submit edit
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    if (!editText.trim()) {
      AlertModal.api.showAlert("Comment cannot be empty!", {
        type: ALERT_TYPES.WARNING,
      });
      return;
    }
    if (!token) {
      AlertModal.api.showAlert("You need to be logged in to edit comments.", {
        type: ALERT_TYPES.WARNING,
      });
      return;
    }

    try {
      await editComment({
        commentId: comment.id,
        commentData: { comment: editText.trim() },
        token,
      }).unwrap();

      setIsEditing(false);
      refetchQuestionStatus();
    } catch (err) {
      console.error("Failed to edit comment:", err);
      AlertModal.api.showAlert(
        "Could not update comment: " +
          (err.data?.message || err.message || "Unknown error"),
        {
          type: ALERT_TYPES.ERROR,
        }
      );
    }
  };

  // Submit a reply
  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyText.trim()) {
      AlertModal.api.showAlert("Reply is empty!", {
        type: ALERT_TYPES.WARNING,
      });
      return;
    }
    if (!token) {
      AlertModal.api.showAlert("You need to be logged in to reply.", {
        type: ALERT_TYPES.WARNING,
      });
      return;
    }
    const payload = {
      parent_id: comment.id,
      question_slug: questionSlug,
      comment: replyText.trim(),
    };

    try {
      await replyToComment({ replyData: payload, token }).unwrap();
      setReplyText("");
      setShowReplyForm(false);
      refetchQuestionStatus();
    } catch (err) {
      console.error("Failed to reply:", err);
      AlertModal.api.showAlert("Could not post reply", {
        type: ALERT_TYPES.ERROR,
      });
    }
  };



  return (
    <div style={{ marginBottom: "1rem", marginLeft: indent }}>
      {/* Comment author + date */}
      <div style={{ fontWeight: "bold" }}>
        {comment.author}
        <span style={{ color: "#888", fontSize: "0.85rem", marginLeft: "8px" }}>
          {new Date(comment.date).toLocaleString()}
        </span>
      </div>

      {/* Comment text or Edit form */}
      {isEditing ? (
        <Form onSubmit={handleSubmitEdit}>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              required
              maxLength={1000}
            />
          </Form.Group>
          <div className="char-count">{editText.length}/1000</div>
          <div style={{ marginTop: "6px", display: "flex", gap: "8px" }}>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              disabled={isEditingComment}
              className="d-flex align-items-center gap-2">
              Save Changes
            </Button>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleCancelEdit}
              disabled={isEditingComment}
              className="d-flex align-items-center gap-2">
              Cancel
            </Button>
          </div>
        </Form>
      ) : (
        <div>{comment.text}</div>
      )}

      {/* Reply/Edit/Delete Buttons - only show if not currently editing */}
      {!isEditing && (
        <div style={{ marginTop: "6px", display: "flex", gap: "8px" }}>
          <Button
            className="d-flex align-items-center gap-2"
            variant="outline-primary"
            size="sm"
            onClick={() => setShowReplyForm(!showReplyForm)}
            disabled={isReplying || isDeleting || isEditingComment}
            title="Reply to comment">
            <FaReply style={{ fontSize: "0.85rem" }} /> <span>Reply</span>
          </Button>

          {/* Show edit button if it's the user's comment */}
          {canEdit && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleStartEdit}
              disabled={isReplying || isDeleting || isEditingComment}
              title="Edit Comment"
              className="d-flex align-items-center gap-2">
              <FaEdit style={{ fontSize: "0.85rem" }} /> <span>Edit</span>
            </Button>
          )}

          {/* Show delete button if it's the user's comment */}
          {canDelete && (
            <Button
              variant="outline-danger"
              size="sm"
              onClick={handleDelete}
              disabled={isReplying || isDeleting || isEditingComment}
              title="Delete Comment">
              <FaTrash />
            </Button>
          )}
        </div>
      )}

      {/* If user clicked "Reply," show a small form */}
      {showReplyForm && (
        <Form onSubmit={handleReplySubmit} style={{ marginTop: "8px" }}>
          <Form.Group>
            <Form.Label>Your Reply</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              required
              maxLength={1000}
            />
          </Form.Group>
          <div className="char-count">{replyText.length}/1000</div>
          <Button
            type="submit"
            variant="primary"
            size="sm"
            style={{ marginTop: "6px" }}
            disabled={isReplying}>
            {isReplying ? "Sending..." : "Submit Reply"}
          </Button>
        </Form>
      )}

      {/* Render child replies recursively (if any) */}
      {comment.replies && comment.replies.length > 0 && (
        <div
          style={{
            marginTop: "8px",
            borderLeft: "1px solid #ccc",
            paddingLeft: "8px",
          }}>
          {comment.replies.map((child) => (
            <CommentItem
              key={child.id}
              comment={child}
              questionSlug={questionSlug}
              token={token}
              refetchQuestionStatus={refetchQuestionStatus}
              indent={indent + 20}
            />
          ))}
        </div>
      )}
    </div>
  );
}

/**
 * Main component that shows question feedback: likes, dislikes, resolved, favorites,
 * plus a list of top-level comments + replies. Also a form to add a new top-level comment.
 */
const QuestionComments = ({ question }) => {
  // Expand/collapse comments
  const [showComments, setShowComments] = useState(false);

  // For a new top-level comment
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [likesCount, setLikesCount] = useState(0);
  const [dislikesCount, setDislikesCount] = useState(0);
  const [questionResolved, setQuestionResolved] = useState(false);

  const [userInteraction, setUserInteraction] = useState({
    liked: false,
    disliked: false,
  });

  // "favorite" logic
  const [isFavorite, setIsFavorite] = useState(false);
  const [addingToFavorites, setAddingToFavorites] = useState(false);

  // RTK Query hooks
  const [addFeedback, { isLoading: isAddingFeedback }] =
    useAddFeedbackMutation();
  const [getQuestionStatus] = useQuestionStatusMutation();
  const [toggleResolved, { isLoading: isTogglingResolved }] =
    useToggleResolvedMutation();

  const commentsContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  };

  // Retrieve token from localStorage (or from Redux)
  const token = localStorage.getItem("token");

  const sortCommentsRecursively = (comments) => {
    // Sort the provided comments by date (ascending order)
    const sortedComments = [...comments].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    // Recursively sort replies of each comment
    return sortedComments.map((comment) => {
      if (comment.replies && comment.replies.length > 0) {
        return {
          ...comment,
          replies: sortCommentsRecursively(comment.replies),
        };
      }
      return comment;
    });
  };

  // Re-fetch question status (comments, likes, etc.)
  const fetchQuestionStatus = async () => {
    if (!token) {
      console.warn("No token found for question status");
      return;
    }
    try {
      const response = await getQuestionStatus({
        feedbackData: { question_slug: question.slug || "" },
        token,
      }).unwrap();

      console.log("RESPONSE from questionstatus:", response);

      setLikesCount(response.likes_count || 0);
      setDislikesCount(response.dislikes_count || 0);
      setQuestionResolved(response.status || false);

      setUserInteraction({
        liked: response.user_liked || false,
        disliked: response.user_disliked || false,
      });

      // The back-end returns "comments" with .replies for nested
      if (response.comments && Array.isArray(response.comments)) {
        const sortedComments = sortCommentsRecursively(response.comments);
        setComments(sortedComments);
      }
    } catch (err) {
      console.error("Failed to fetch question status:", err);
    }
  };

  // Like/Dislike
  const handleFeedback = async (feedbackType, e) => {
    e.stopPropagation();
    if (!token) {
      alert("Login required for like/dislike");
      return;
    }

    // UI: optimistic
    if (feedbackType === "like") {
      if (userInteraction.liked) {
        setLikesCount((prev) => Math.max(prev - 1, 0));
        setUserInteraction((prev) => ({ ...prev, liked: false }));
      } else {
        setLikesCount((prev) => prev + 1);
        if (userInteraction.disliked) {
          setDislikesCount((prev) => Math.max(prev - 1, 0));
        }
        setUserInteraction({ liked: true, disliked: false });
      }
    } else {
      // "dislike"
      if (userInteraction.disliked) {
        setDislikesCount((prev) => Math.max(prev - 1, 0));
        setUserInteraction((prev) => ({ ...prev, disliked: false }));
      } else {
        setDislikesCount((prev) => prev + 1);
        if (userInteraction.liked) {
          setLikesCount((prev) => Math.max(prev - 1, 0));
        }
        setUserInteraction({ liked: false, disliked: true });
      }
    }

    try {
      const payload = {
        feedback_type: feedbackType,
        question_slug: question.slug || "",
        curriculum_id: question.currm_id || 0,
        subject_id: question.apsub_id || 0,
        course_id: question.apcourse_id || 0,
        unit_id: question.unit_id || 0,
        topic_id: question.topics_id || 0,
        like_or_dislike: 1,
      };
      await addFeedback({ feedbackData: payload, token }).unwrap();
      fetchQuestionStatus();
    } catch (err) {
      console.error(`Failed to ${feedbackType}:`, err);
      alert(`Could not ${feedbackType} this question`);
      fetchQuestionStatus();
    }
  };

  // Toggle resolved
  const handleToggleResolved = async (e) => {
    e.stopPropagation();
    if (!token) {
      alert("Login required to mark resolved");
      return;
    }
    const newResolvedState = !questionResolved;
    setQuestionResolved(newResolvedState);

    try {
      const resp = await toggleResolved({
        data: {
          question_slug: question.slug,
          status: newResolvedState,
        },
        token,
      }).unwrap();

      setQuestionResolved(resp.status);
      fetchQuestionStatus();
    } catch (err) {
      setQuestionResolved(!newResolvedState);
      console.error("Failed to toggle resolved:", err);
      alert("Failed to update question status");
    }
  };

  // Submit new top-level comment
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!comment.trim()) return;
    if (!token) {
      alert("You must be logged in to comment");
      return;
    }

    const payload = {
      feedback_type: "comment",
      comment: comment.trim(),
      question_slug: question.slug || "",
      curriculum_id: question.currm_id || 0,
      subject_id: question.apsub_id || 0,
      course_id: question.apcourse_id || 0,
      unit_id: question.unit_id || 0,
      topic_id: question.topics_id || 0,
      like_or_dislike: 0,
    };

    try {
      await addFeedback({ feedbackData: payload, token }).unwrap();
      setComment("");
      await fetchQuestionStatus();
      // Scroll to bottom after the comment is added and displayed
      setTimeout(scrollToBottom, 100); // Small delay to ensure comments are rendered
    } catch (err) {
      console.error("Failed to add comment:", err);
      alert("Unable to post comment");
    }
  };

  // Toggle open/close comments
  const toggleComments = (e) => {
    e.stopPropagation();
    setShowComments(!showComments);
    if (!showComments && comments.length === 0) {
      fetchQuestionStatus();
    }
  };

  // "favorite" logic
  const toggleFavorite = (e) => {
    e.stopPropagation();
    if (!token) {
      alert("Login required to favorite");
      return;
    }
    setIsFavorite(!isFavorite);
    setAddingToFavorites(true);
    setTimeout(() => {
      setAddingToFavorites(false);
    }, 300);
  };

  // On mount or question change
  useEffect(() => {
    if (question.slug) {
      fetchQuestionStatus();
    }
    // eslint-disable-next-line
  }, [question.slug]);

  const countTotalComments = (commentsList) => {
    let total = 0;
    
    if (!commentsList || !Array.isArray(commentsList)) {
      return 0;
    }
    
    // Count top-level comments
    total += commentsList.length;
    
    // Count all nested replies recursively
    commentsList.forEach(comment => {
      if (comment.replies && Array.isArray(comment.replies)) {
        total += countTotalComments(comment.replies);
      }
    });
    
    return total;
  };

  return (
    <div
      className="question-feedback-container"
      onClick={(e) => e.stopPropagation()}>
      {/* Like / Dislike / Resolve / Favorite */}
      <div className="question-actions">
        <Button
          variant={userInteraction.liked ? "success" : "outline-success"}
          className={`like-button ${userInteraction.liked ? "active" : ""}`}
          onClick={(e) => handleFeedback("like", e)}
          disabled={isAddingFeedback}>
          <FaThumbsUp /> <span>{likesCount}</span>
        </Button>

        <Button
          variant={userInteraction.disliked ? "danger" : "outline-danger"}
          className={`dislike-button ${
            userInteraction.disliked ? "active" : ""
          }`}
          onClick={(e) => handleFeedback("dislike", e)}
          disabled={isAddingFeedback}>
          <FaThumbsDown /> <span>{dislikesCount}</span>
        </Button>

        <Button
          variant={questionResolved ? "success" : "outline-success"}
          className={`resolve-button ${questionResolved ? "active" : ""}`}
          onClick={handleToggleResolved}
          disabled={isTogglingResolved}>
          <FaCheckCircle /> {questionResolved ? "Resolved" : "Mark Resolved"}
        </Button>

        <Button
          variant={isFavorite ? "primary" : "outline-primary"}
          className={`favorite-button ${isFavorite ? "active" : ""}`}
          onClick={toggleFavorite}
          disabled={addingToFavorites}>
          {isFavorite ? <FaStar /> : <FaRegStar />}{" "}
          {isFavorite ? "Favorited" : "Favorite"}
        </Button>
      </div>

      {/* Comments Section */}
      <div className="comments-section">
        <div className="comments-header" onClick={toggleComments}>
          {showComments ? (
            <FaComment color="#0083af" />
          ) : (
            <FaRegCommentDots color="#0083af" />
          )}
          <span className="comments-title">Discuss The Question!</span>
          <span className="comment-count">{countTotalComments(comments)}</span>
        </div>

        {showComments && (
          <>
            <div className="comments-container" ref={commentsContainerRef}>
              {comments.length > 0 ? (
                comments.map((c) => (
                  <CommentItem
                    key={c.id}
                    comment={c}
                    questionSlug={question.slug}
                    token={token}
                    refetchQuestionStatus={fetchQuestionStatus}
                    indent={0}
                  />
                ))
              ) : (
                <div className="no-comments">
                  Be the first to start a discussion about this question!
                </div>
              )}
            </div>
            {/* New top-level comment */}
            <Form onSubmit={handleSubmitComment} className="comment-form">
              <Form.Control
                type="text"
                placeholder="Add your comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="comment-input"
                disabled={isAddingFeedback}
                maxLength={1000}
              />
              <div className="char-count">{comment.length}/1000</div>
              <Button
                type="submit"
                className="comment-submit"
                disabled={isAddingFeedback || !comment.trim()}>
                <FaPaperPlane size={14} />
                {isAddingFeedback ? "Sending..." : "Send"}
              </Button>
            </Form>
          </>
        )}
      </div>

      <AlertModal />
    </div>
  );
};

export default QuestionComments;
