import React, { useEffect, useState } from 'react';
import View from './View';
import './questionbank.css';
import Footer from '../layout/footer/Footer';

const Question = (props) => {
  const [blogCount, setBlogCount] = useState(6);
  const [blogs, setBlogs] = useState([]);
  const [filterValue, setFilterValue] = useState('All');
  const propsdata = props.match.params.slug;

  // useEffect(() => {
  //   setBlogs(allBlogs.slice(0, 6))
  // }, [allBlogs])

  const _handleBlogs = () => {
    const newBlogCount = blogCount + 3;
    setBlogCount(newBlogCount);
  };

  return (
    <section>
      <View
        blogs={blogs}
        handleBlogs={_handleBlogs}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        propsData={propsdata}
      />
      <Footer />
    </section>
  );
};

export default Question;
