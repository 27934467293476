import React, { useState, useEffect } from "react";
import "./Unit.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";
import FormulaIcon from "../img/FormulaIcon.png";
import MarkSchemeIcon from "../img/MarkSchemeIcon.png";
import SimilarQuestionIcon from "../img/SimilarQuestionIcon.png";
import VideoAnswerIcon from "../img/VideoAnswerIcon.png";

import { useGetAlltopicQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import BGRight2 from "../img/Bg Right 2.png"; // background image
import { useLocation } from "react-router-dom";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { AiOutlineExpand, AiOutlineCompress } from "react-icons/ai";
import { useSelector } from "react-redux";
import MathJaxRenderer from "../MathJaxRenderer";
import QuestionComments from "./QuestionComments";

const Unit = (props) => {
  const history = useHistory();

  const purchasedCourses = useSelector((state) => state.user.purchasedCourses);
  const masterPromo = useSelector((state) => state.user.masterPromo);

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };

  const topicdata = useGetAlltopicQuery(props.match.params.slug);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [iso] = useState(null);
  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cardprops, setCardprops] = useState();
  const [fetchedData, setfetchedData] = useState(null);
  const [questions, setQuestions] = useState(null);

  // Modal states
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkSchemeImage, setCurrentMarkSchemeImage] = useState(null);
  const [currentMarkSchemeHTML, setCurrentMarkSchemeHTML] = useState(null);

  // Topic & question filtering states
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState("All"); // 'Easy' | 'Medium' | 'Hard' | 'All'

  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  // Fullscreen state
  const [isFullscreen, setIsFullscreen] = useState(false);

  const location = useLocation();

  const [visibleQuestionIndex, setVisibleQuestionIndex] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true); // Assuming we can scroll right initially

  const [activeScrollInterval, setActiveScrollInterval] = useState(null);

  // Helper function to convert YouTube URLs to embed URLs
  const getEmbedUrl = (url) => {
    if (!url) return "";
    try {
      const parsedUrl = new URL(url);
      // Check if it's a YouTube watch URL
      if (
        parsedUrl.hostname.includes("youtube.com") &&
        parsedUrl.pathname === "/watch"
      ) {
        const videoId = parsedUrl.searchParams.get("v");
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
      // Check if it's a shortened YouTube URL (youtu.be)
      if (parsedUrl.hostname === "youtu.be") {
        const videoId = parsedUrl.pathname.slice(1); // remove leading '/'
        if (videoId) return `https://www.youtube.com/embed/${videoId}`;
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
    // Fallback: return the original URL if it doesn't match our criteria
    return url;
  };

  /* Mark Scheme */

  const handleOpenQuestionModal = (question) => {
    setCurrentQuestion(question);
    setShowQuestionModal(true);
  };

  const handleOpenMarkSchemeModal = (htmlString) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    // Store the original HTML string
    setCurrentMarkSchemeHTML(htmlString);

    setShowMarkSchemeModal(true);
  };
  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
    setCurrentMarkSchemeImage(null);
  };

  /* Video Answer */
  const handleOpenVideoModal = (videoURL) => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    setCurrentVideoURL(videoURL);
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideoURL(null);
  };

  /* Question Navigation */
  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
    const questionElement = document.getElementById(
      `questions-container-${index}`
    );
    if (questionElement) {
      questionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  /* Filtered Questions by Difficulty */
  const filteredQuestions = questions?.filter((question, index) => {
    question.originalIndex = index; // store the original index
    if (selectedDifficulty === "All") return true;
    return question.status === selectedDifficulty;
  });

  useEffect(() => {
    const scrollContainer = document.getElementById("question-list-scroll");

    if (scrollContainer) {
      let lastScrollLeft = 0;

      const handleScroll = () => {
        const currentScrollLeft = scrollContainer.scrollLeft;
        const maxScrollLeft =
          scrollContainer.scrollWidth - scrollContainer.clientWidth;

        // Update the scroll position state
        setScrollPosition(currentScrollLeft);

        // Check if we can scroll in either direction
        setCanScrollLeft(currentScrollLeft > 10);
        setCanScrollRight(currentScrollLeft < maxScrollLeft - 10);

        if (currentScrollLeft > lastScrollLeft) {
          console.log("User scrolled right on question list");
          console.log("Current scroll position:", currentScrollLeft);
          console.log("Max scroll position:", maxScrollLeft);
        } else if (currentScrollLeft < lastScrollLeft) {
          console.log("User scrolled left on question list");
          console.log("Current scroll position:", currentScrollLeft);
        }

        lastScrollLeft = currentScrollLeft;
      };

      // Add the scroll event listener
      scrollContainer.addEventListener("scroll", handleScroll);

      // Initial check
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      setCanScrollRight(maxScrollLeft > 10); // Only show if we actually have content to scroll to
      setScrollPosition(scrollContainer.scrollLeft);

      // Clean up the event listener on component unmount
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [filteredQuestions]); // Re-add the listener when the questions change

  /* Formula Modal */
  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };

  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };

  const extractImageUrls = (htmlString) => {
    const imgTags = htmlString.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
    if (!imgTags) return [];
    const srcRegex = /src="([^"]*)"/;
    return imgTags
      .map((imgTag) => {
        const match = imgTag.match(srcRegex);
        return match ? match[1] : null;
      })
      .filter(Boolean);
  };

  // Add this useEffect to set up the Intersection Observer
  useEffect(() => {
    if (!filteredQuestions || filteredQuestions.length === 0) return;

    // Create an observer instance
    const observer = new IntersectionObserver(
      (entries) => {
        // Find the entry with the highest intersection ratio
        const visibleEntry = entries.reduce((prev, current) => {
          return prev && prev.intersectionRatio > current.intersectionRatio
            ? prev
            : current;
        });

        if (visibleEntry && visibleEntry.intersectionRatio > 0.2) {
          // Extract the index from the element id
          const id = visibleEntry.target.id;
          const index = parseInt(id.split("-").pop());

          // Update the visible question index
          setVisibleQuestionIndex(index);
          setSelectedQuestionIndex(index);
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5], // Multiple thresholds for better accuracy
      }
    );

    // Observe all question containers
    filteredQuestions.forEach((_, index) => {
      const element = document.getElementById(`questions-container-${index}`);
      if (element) observer.observe(element);
    });

    // Clean up
    return () => {
      filteredQuestions.forEach((_, index) => {
        const element = document.getElementById(`questions-container-${index}`);
        if (element) observer.unobserve(element);
      });
    };
  }, [filteredQuestions]); // Re-run when filtered questions change

  /* Fetch questions from API */
  const fetchQuestions = async (topicSlug) => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/questionbankquestion/${topicSlug}`
      );
      setQuestions(response.data);
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError(err.message);
    }
  };

  /* Fetch topics (and general data) from API */
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/aptopicdata/${props.match.params.slug}`
      );
      setfetchedData(response.data);
      setCardprops(response.data[0].slug); // default to first returned slug
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  /* Fullscreen toggling */
  const toggleFullscreen = (index) => {
    const container = document.getElementById(`questions-container-${index}`);
    if (container) {
      if (!document.fullscreenElement) {
        container.requestFullscreen().catch((err) => {
          console.error(
            `Error attempting to enable fullscreen: ${err.message}`
          );
        });
        setIsFullscreen(true);
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(`Error attempting to exit fullscreen: ${err.message}`);
        });
        setIsFullscreen(false);
      }
    } else {
      console.error("Container not found: Cannot toggle fullscreen");
    }
  };

  /* Initial fetch */
  useEffect(() => {
    fetchData();
  }, [props.match.params.slug]);

  useEffect(() => {
    if (topicdata.data && topicdata.data.length > 0) {
      // Possibly a topic from location.state
      const topicSlugFromState = location.state?.selectedTopicSlug;
      const firstTopic = topicSlugFromState
        ? topicdata.data.find((topic) => topic.slug === topicSlugFromState)
        : topicdata.data[0];

      if (firstTopic) {
        setSelectedTopic(firstTopic.id);
        setCardprops(firstTopic.slug);
        fetchQuestions(firstTopic.slug);
      }
    }
  }, [topicdata.data, location.state]);

  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (topicdata.isError)
    return <h1>An error occurred {topicdata.error.error}</h1>;

  /* Check user course access (based on purchasedCourses) */
  const isCourseAccessible = (courseId) => {
    if (masterPromo === true) {
      return true;
    }

    const course = purchasedCourses.find((c) => c.course_id === courseId);

    if (!course) return false; // Not purchased

    const today = new Date().getTime() / 1000; // Convert current time to Unix timestamp
    return course.end_date >= today; // Returns true if plan is active
  };

  const neededCourseId = topicdata.data[0]?.apcourse_id; // use apcourse_id

  // If the user does NOT have access, redirect
  if (!isCourseAccessible(neededCourseId)) {
    history.push("/payment");
    return null; // stop rendering, so no further UI is shown
  }

  return (
    <div className="questionbank-unit">
      {/* Top Section & Header */}
      <div className="top-header-quetion">
        <Header />
        <section className="ap">
          {/* Replaced <Container> <Row> <Col> with pure divs */}
          <div className="my-container">
            <div className="my-row pb-60">
              <div className="my-col-sm-12">
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(2)}>
                        {topicdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {topicdata.data[0].unitname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>

              <div className="my-col-sm-12">
                <div className="qb-heading">
                  <h2>{topicdata.data[0].couresname} - Question Bank</h2>
                  {/* Another row replaced with plain divs */}
                  <div className="my-row">
                    <div className="my-col-sm-8">
                      <MathJaxRenderer>
                        <p className="course-description">
                          {HTMLReactParser(topicdata.data[0].couresdescription)}
                        </p>
                      </MathJaxRenderer>
                    </div>

                    <div className="my-col-sm-4">
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" alt="Quiz Icon 1" />
                        <img src={qu_top_w} alt="Quiz Icon 2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Main Section */}
      <section className="unit-page pt-60 pb-120">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <div className="my-row">
          {/* Sidebar with Topics (replacing <Col sm={3} />) */}
          <div className="my-col-sm-3 sticky-column">
            <h3 className="unit-title">{topicdata.data[0].unitname}</h3>
            {topicdata.data.map((topic) => (
              <Button
                key={topic.id}
                onClick={() => {
                  setCardprops(topic.slug);
                  fetchQuestions(topic.slug);
                  setSelectedTopic(topic.id);
                  setSelectedQuestionIndex(0);
                  setVisibleQuestionIndex(0);
                  setTimeout(() => {
                    const firstQuestion = document.getElementById(
                      "questions-container-0"
                    );
                    if (firstQuestion) {
                      const rect = firstQuestion.getBoundingClientRect();
                      const absoluteY = window.scrollY + rect.top - 200; // 100px offset upward
                      window.scrollTo({ top: absoluteY, behavior: "smooth" });
                    }
                  }, 300); // slightly longer timeout to ensure rendering
                }}
                variant="outline-primary"
                className={`topic-button ${
                  selectedTopic === topic.id ? "selected" : ""
                }`}
              >
                <span className="topic-button-text">{topic.name}</span>
                <FaChevronRight className="topic-button-icon" />
              </Button>
            ))}
          </div>

          {/* Questions & Filters (replacing <Col sm={8} />) */}
          <div className="my-col-sm-8">
            {/* Difficulty Filters (replacing <Col sm={11} /> if needed) */}
            <div className="my-col-sm-11">
              <div className="difficulty-filters">
                <Button
                  onClick={() => setSelectedDifficulty("Easy")}
                  className={selectedDifficulty === "Easy" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={EasyIcon} alt="Easy" />
                  Easy
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Medium")}
                  className={selectedDifficulty === "Medium" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={MediumIcon} alt="Medium" />
                  Medium
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Hard")}
                  className={selectedDifficulty === "Hard" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={HardIcon} alt="Hard" />
                  Hard
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("All")}
                  className={selectedDifficulty === "All" ? "active" : ""}
                  variant="outline-primary"
                >
                  All
                </Button>
              </div>
            </div>

            {filteredQuestions && filteredQuestions.length > 0 ? (
              <div>
                {/* Question List Scroll */}
                <div className="question-list-scroll-container">
                  {/* Left Arrow Indicator - Only show when we can scroll left */}
                  {canScrollLeft && (
                    <div
                      className="scroll-arrow-indicator scroll-arrow-left"
                      onMouseDown={() => {
                        // Clear any existing interval first
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }

                        // Start scrolling left on mouse down
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            scrollContainer.scrollBy({
                              left: -10,
                              behavior: "auto",
                            });

                            // Check if we've reached the left boundary and stop scrolling
                            if (scrollContainer.scrollLeft <= 0) {
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);

                          // Save the interval ID in state for cleanup
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onMouseUp={() => {
                        // Stop scrolling on mouse up
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onMouseLeave={() => {
                        // Stop scrolling when mouse leaves
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onTouchStart={() => {
                        // Clear any existing interval first
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }

                        // Start scrolling left on touch
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            scrollContainer.scrollBy({
                              left: -10,
                              behavior: "auto",
                            });

                            // Check if we've reached the left boundary and stop scrolling
                            if (scrollContainer.scrollLeft <= 0) {
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);

                          // Save the interval ID in state for cleanup
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onTouchEnd={() => {
                        // Stop scrolling when touch ends
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onTouchCancel={() => {
                        // Also handle touch cancel events
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                    >
                      <FaChevronLeft />
                    </div>
                  )}
                  <div
                    className="question-list-scroll"
                    id="question-list-scroll"
                  >
                    {filteredQuestions.map((question, index) => (
                      <div
                        key={index}
                        onClick={() => handleQuestionClick(index)}
                        className={`question-list-item ${
                          visibleQuestionIndex === index ||
                          selectedQuestionIndex === index
                            ? "active"
                            : ""
                        }`}
                      >
                        Question {question.originalIndex + 1}
                      </div>
                    ))}
                  </div>
                  {/* Right Arrow Indicator - Only show when we can scroll right */}

                  {canScrollRight && (
                    <div
                      className="scroll-arrow-indicator scroll-arrow-right"
                      onMouseDown={() => {
                        // Clear any existing interval first
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }

                        // Start scrolling right on mouse down
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            // Get the maximum scrollable distance
                            const maxScrollLeft =
                              scrollContainer.scrollWidth -
                              scrollContainer.clientWidth;

                            // Only scroll if we're not at the right edge
                            if (scrollContainer.scrollLeft < maxScrollLeft) {
                              scrollContainer.scrollBy({
                                left: 10,
                                behavior: "auto",
                              });
                            } else {
                              // We've reached the end, stop scrolling
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);

                          // Save the interval ID in state for cleanup
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onMouseUp={() => {
                        // Stop scrolling on mouse up
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onMouseLeave={() => {
                        // Stop scrolling when mouse leaves
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onTouchStart={() => {
                        // Clear any existing interval first
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                        }

                        // Start scrolling right on touch
                        const scrollContainer = document.getElementById(
                          "question-list-scroll"
                        );
                        if (scrollContainer) {
                          const scrollInterval = setInterval(() => {
                            // Get the maximum scrollable distance
                            const maxScrollLeft =
                              scrollContainer.scrollWidth -
                              scrollContainer.clientWidth;

                            // Only scroll if we're not at the right edge
                            if (scrollContainer.scrollLeft < maxScrollLeft) {
                              scrollContainer.scrollBy({
                                left: 10,
                                behavior: "auto",
                              });
                            } else {
                              // We've reached the end, stop scrolling
                              clearInterval(scrollInterval);
                              setActiveScrollInterval(null);
                            }
                          }, 10);

                          // Save the interval ID in state for cleanup
                          setActiveScrollInterval(scrollInterval);
                        }
                      }}
                      onTouchEnd={() => {
                        // Stop scrolling when touch ends
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                      onTouchCancel={() => {
                        // Also handle touch cancel events
                        if (activeScrollInterval) {
                          clearInterval(activeScrollInterval);
                          setActiveScrollInterval(null);
                        }
                      }}
                    >
                      <FaChevronRight />
                    </div>
                  )}
                </div>

                {/* Render Each Question */}
                {filteredQuestions.map((question, index) => (
                  <div
                    key={question.originalIndex}
                    className="questions-container"
                    id={`questions-container-${index}`}
                    onClick={() => {
                      if (window.innerWidth <= 1025) {
                        handleOpenQuestionModal(question);
                      }
                    }}
                  >
                    {/* Question Header */}
                    <div className="title-container">
                      <h3 className="h3-title">
                        Question {question.originalIndex + 1}
                      </h3>

                      {/* Difficulty Icon */}
                      {question.status === "Easy" && (
                        <img
                          src={EasyIcon}
                          alt="Easy Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Medium" && (
                        <img
                          src={MediumIcon}
                          alt="Medium Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Hard" && (
                        <img
                          src={HardIcon}
                          alt="Hard Icon"
                          className="status-icon"
                        />
                      )}
                    </div>

                    {/* Formula Button */}
                    {question.formula && (
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenFormulaModal(question.formula);
                        }}
                        className="formulaButton"
                      >
                        <img
                          src={FormulaIcon}
                          alt="Formulas Icon"
                          className="formulaIcon"
                        />
                        <span className="formulatext">Formulas</span>
                      </Button>
                    )}

                    {/* Question Content */}
                    <div className="question-photo">
                      <MathJaxRenderer>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.description.replace(
                              /<img /g,
                              '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                            ),
                          }}
                        />
                      </MathJaxRenderer>
                    </div>

                    {/* Additional Buttons */}
                    <div className="additional-buttons">
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenMarkSchemeModal(question.photo);
                        }}
                        className="markscheme-button"
                      >
                        <img
                          src={MarkSchemeIcon}
                          alt="Mark Scheme Icon"
                          className="markscheme-icon"
                        />
                        Mark Scheme
                      </Button>

                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenVideoModal(question.video);
                        }}
                        className="videoanswer-button"
                      >
                        <img
                          src={VideoAnswerIcon}
                          alt="Video Answer Icon"
                          className="videoanswer-icon"
                        />
                        Video Answer
                      </Button>

                      <Button
                        variant="link"
                        onClick={() =>
                          history.push(`/Similar-Question/${question.slug}/`)
                        }
                        className="similarquestion-button"
                      >
                        <img
                          src={SimilarQuestionIcon}
                          alt="Similar Question Icon"
                          className="similarquestion-icon"
                        />
                        More Similar Questions
                      </Button>

                      <Button
                        variant="link"
                        onClick={() => toggleFullscreen(index)}
                        className="fullscreen-button"
                        title={
                          isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"
                        }
                      >
                        {isFullscreen ? (
                          <AiOutlineCompress size={24} />
                        ) : (
                          <AiOutlineExpand size={24} />
                        )}
                      </Button>
                    </div>
                    <QuestionComments question={question} />
                  </div>
                ))}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </section>

      <Modal
        show={showQuestionModal}
        onHide={() => setShowQuestionModal(false)}
        fullscreen={true} // Makes the modal full screen on mobile devices
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Question {currentQuestion ? currentQuestion.originalIndex + 1 : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentQuestion && (
            <div>
              <MathJaxRenderer>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentQuestion.description.replace(
                      /<img /g,
                      '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                    ),
                  }}
                />
              </MathJaxRenderer>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Formula Modal */}
      <Modal
        show={showFormulaModal}
        onHide={handleCloseFormulaModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFormulaImage && (
            <img
              src={currentFormulaImage}
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
              alt="Formula"
            />
          )}
        </Modal.Body>
      </Modal>
      {/* Video Modal */}
      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVideoURL ? (
            <div className="video-container">
              <iframe
                src={getEmbedUrl(currentVideoURL)}
                style={{ width: "100%", height: "400px" }}
                title="Video Answer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </Modal.Body>
      </Modal>
      {/* Mark Scheme Modal */}
      <Modal
        show={showMarkSchemeModal}
        onHide={handleCloseMarkSchemeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentMarkSchemeHTML ? (
            <MathJaxRenderer>
              {HTMLReactParser(currentMarkSchemeHTML, {
                replace: (domNode, index) => {
                  // Check if the node is an <img> tag.
                  if (domNode.type === "tag" && domNode.name === "img") {
                    return (
                      <img
                        key={index}
                        src={domNode.attribs.src}
                        alt={
                          domNode.attribs.alt ||
                          `Mark Scheme image ${index + 1}`
                        }
                        style={{
                          width: "100%",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                    );
                  }
                },
              })}
            </MathJaxRenderer>
          ) : (
            <p>No Mark Scheme available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default Unit;
