import React, { useEffect, useContext, useState } from 'react';
import Error from '../errordata/Error';
import Box from './Box';
import './quiz.css';


export default function Question(props) {


  const [current, setCurrent] = useState(0);


  if (!props) {
    return <Error />;
  }
  return (
    <>
      <Box
        current={current}
        next={setCurrent}
        props={props}
      />
    </>
  );
}
