import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import Right_arror from '../img/icon/right-arror.png';
import Right_arror_w from '../img/icon/Expand_right_light_w.png';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import "../key-concept/KeyTopic.css"

// Quiz Topic component with same styling as KeyTopic
const QuizTopic = ({ unitData }) => {
  if (!unitData || unitData.length === 0)
    return (
      <div className="coming-soon glass-card">
        <h1>Coming Soon</h1>
      </div>
    );

  return (
    <div className="topic-container">
      <Row>
        {unitData.map((unit) => (
          <Col sm={4} key={unit.id || unit.slug} id={unit.unitnumber} className="topic-col">
            <NavLink 
              to={'/Quiz-Unit/' + unit.slug} 
              className="topic-card glass-card"
            >
              <div className="topic-content">
                <h4>Unit {unit.unitnumber} - {unit.name}</h4>
                <div className="topic-arrow">
                  <img src={Right_arror} className="d" alt="arrow" />
                </div>
              </div>
              <div className="hover-indicator"></div>
            </NavLink>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default QuizTopic;