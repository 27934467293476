import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useGetAllletestcoursesQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";

// Import new SVG icons
import KeyIcon from "../img/icon/card_key.png";
import QuestionIcon from "../img/icon/card_quiz.png";
import PastIcon from "../img/icon/card_book.png";
import QuizIcon from "../img/icon/card_q.png";
import APLogo from "../img/AP_logo.png";

// CSS styles for the enhanced component
const styles = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

:root {
  --white: #ffffff;
  --background: #f8fafa;
  --text-dark: #2a2a2a;
  --text-medium: #555555;
  --card-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  --card-hover-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  --transition-fast: all 0.2s ease;
  --transition-normal: all 0.3s ease;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--background);
  overflow-x: hidden;
}

/* Background animation - abstract educational motifs */
.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(135deg, #f0f8ff 0%, #e6f0ff 100%);
}

.bg-node {
  position: absolute;
  border-radius: 50%;
  background: var(--lime-green-light);
  box-shadow: 0 0 20px rgba(50, 205, 50, 0.2);
  opacity: 0.4;
}

.bg-node:nth-child(1) {
  width: 300px;
  height: 300px;
  top: 10%;
  left: -100px;
  animation: float 20s infinite ease-in-out;
}

.bg-node:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50%;
  right: -80px;
  animation: float 25s infinite ease-in-out reverse;
}

.bg-node:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: 10%;
  left: 20%;
  animation: float 18s infinite ease-in-out;
  animation-delay: 5s;
}

.bg-node:nth-child(4) {
  width: 250px;
  height: 250px;
  top: 5%;
  right: 20%;
  animation: float 22s infinite ease-in-out;
  animation-delay: 3s;
}

.bg-line {
  position: absolute;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--lime-green-medium), transparent);
  animation: slide 15s infinite linear;
}

.bg-line:nth-child(5) {
  width: 100%;
  top: 25%;
  animation-delay: 0s;
}

.bg-line:nth-child(6) {
  width: 100%;
  top: 65%;
  animation-delay: 5s;
}

@keyframes float {
  0%, 100% { transform: translate(0, 0); }
  25% { transform: translate(30px, -30px); }
  50% { transform: translate(0, -60px); }
  75% { transform: translate(-30px, -30px); }
}

@keyframes slide {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

/* Title animation keyframes */
@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dash-stroke {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes reveal {
  0% {
    width: 0;
  }
  100% {
    width: 100px;
  }
}

/* Main container */
.courses-dashboard {
  padding: 60px 0;
  position: relative;
}

/* Section headings */
.dashboard-header {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
}

/* Modified title styles for scroll animation */
/* Force animation with !important to override any conflicts */
.dashboard-title {
  font-size: 42px;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  z-index: 20;
  background: linear-gradient(90deg, #2a2a2a, #4a4a4a, #2a2a2a);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  /* Initial state with !important */
  opacity: 0 !important;
  transform: translateY(20px) !important;
  transition: none !important;
}

/* Animation state with !important */
.dashboard-title.visible {
  opacity: 1 !important;
  transform: translateY(0) !important;
  animation: shimmer 3s infinite linear !important;
  transition: opacity 0.5s ease, transform 0.5s ease !important;
}

.dashboard-title::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 4px;
  background: linear-gradient(90deg, #6862e8, #9375fc, #6862e8);
  border-radius: 2px;
  transition: width 0.8s ease;
}

.dashboard-title.visible::after {
  width: 100px;
}

.dashboard-subtitle {
  font-size: 18px;
  color: var(--text-medium);
  font-weight: 400;
  z-index: 20;
  position: relative;
  
  /* Initial state with !important */
  opacity: 0 !important;
  transform: translateY(20px) !important;
  transition: none !important;
}

/* Animation state with !important */
.dashboard-subtitle.visible {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: opacity 0.5s ease 0.2s, transform 0.5s ease 0.2s !important;
}

/* Card title and main content spacing */
.course-card {
  background: linear-gradient(135deg, #6862e8 0%, #9375fc 100%); /* Default purple gradient */
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  height: auto;
  min-height: 420px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; /* Ensure full width */
  padding: 20px; /* Consistent padding */
  color: white;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  max-width: 100%; /* Prevent overflow */
  overflow: hidden; /* Change to hidden to prevent button overflow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 40;
}

/* OPTION 3: DEEP RICH GRADIENTS WITH OVERLAYS */
/* These gradients add pattern overlays for added depth */

.course-card[data-course="calculus-ab"] {
  background: linear-gradient(135deg, #7028E4 0%, #A367DC 100%);
  box-shadow: 0 10px 25px rgba(112, 40, 228, 0.25);
  position: relative;
}

/* Math AA SL - Yellowish Gold to Amber (keeping this as it's good) */
.course-card[data-course="math-aa-sl"] {
  background: linear-gradient(135deg, #38A3A5 0%, #57B8C0 100%);
  box-shadow: 0 10px 25px rgba(56, 163, 165, 0.25);
  position: relative;
}
/* The other course colors remain unchanged */

/* Calculus BC - Sapphire to Aquamarine */
.course-card[data-course="calculus-bc"] {
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 50%, #7F7FD5 100%);
  box-shadow: 0 10px 25px rgba(30, 60, 114, 0.25);
  position: relative;
}

/* Math AI HL - Amethyst to Orchid */
.course-card[data-course="math-ai-hl"] {
  background: linear-gradient(135deg, #5e2563 0%, #a94eca 100%);
  box-shadow: 0 10px 25px rgba(94, 37, 99, 0.25);
  position: relative;
}

/* Math AA HL - Emerald to Peridot */
.course-card[data-course="math-aa-hl"] {
  background: linear-gradient(135deg, #3CA55C 0%, #B5AC49 100%);
  box-shadow: 0 10px 25px rgba(60, 165, 92, 0.25);
  position: relative;
}

/* Math AI SL - Garnet to Rose */
.course-card[data-course="math-ai-sl"] {
  background: linear-gradient(135deg, #3949ab 0%, #5e72e4 100%);
  box-shadow: 0 10px 25px rgba(57, 73, 171, 0.25);
  position: relative;
}

/* Add this for Option 3 to create a subtle geometric pattern overlay */
.course-card[data-course]::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  border-radius: 16px;
  pointer-events: none;
}

.course-card:hover {
  transform: translateY(-6px);
  box-shadow: var(--card-hover-shadow);
  border: 1px solid var(--lime-green-medium);
}

.course-card[data-course="calculus-ab"]::before {
  background:rgb(117, 0, 88); /* Brighter purple from the gradient */
}

.course-card[data-course="math-aa-sl"]::before {
  background: rgb(40, 125, 142); /* Darker teal instead of amber */
}

.course-card[data-course="calculus-bc"]::before {
  background:rgb(0, 0, 139); /* Brighter blue from the gradient */
}

.course-card[data-course="math-ai-hl"]::before {
  background:rgb(150, 0, 105); /* Orchid from the gradient */
}

.course-card[data-course="math-aa-hl"]::before {
  background:rgb(0, 138, 57); /* Peridot from the gradient */
}

.course-card[data-course="math-ai-sl"]::before {
  background: rgb(45, 60, 145); /* Deeper blue from the gradient */
}

/* Default for any other courses */
.course-card[data-course="other-course"]::before {
  background: #9375fc; /* From the default card gradient */
}

.course-card::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  transform: scaleX(0);
  transform-origin: left;
  transition: var(--transition-normal);
}

.course-card:hover::before {
  transform: scaleX(1);
}

/* Course top section */
.course-top {
  position: relative;
  margin-bottom: 20px;
}

.course-category {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin-bottom: 15px;
}

.course-icon-container {
  position: absolute;
  top: 5%;
  right: 0%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.course-icon {
  max-width: 100%;
  max-height: 100%;
  
}

/* 3D effect images for each course */
.math-icon-3d {
  width: 120px;
  height: 120px;
  position: relative;
  transform-style: preserve-3d;
  transform: perspective(800px) rotateY(-15deg) rotateX(10deg);
  transition: transform 0.5s ease;
}

.course-card:hover .math-icon-3d {
  transform: perspective(800px) rotateY(-5deg) rotateX(5deg) translateY(-10px);
}

/* Course stats */
.course-stats {
  display: flex;
  gap: 10px; /* Slightly reduced */
 
  font-size: 13px; /* Slightly reduced */
  color: rgba(255, 255, 255, 0.9);
  align-items: center;
}

.course-stat {
  display: flex;
  align-items: center;
  gap: 4px; /* Slightly reduced */
}

.stat-icon {
  width: 14px; /* Reduced */
  height: 14px; /* Reduced */
  opacity: 0.8;
}

/* Progress indicator */
.progress-indicator {
  position: absolute;
  left: 24%;
  top: 484%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.progress-icon {
  width: 14px;
  height: 14px;
}

/* "Saved" badge */
.saved-badge {
  position: absolute;
  top: 52%;
  left: 5%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Course title section - reduce width to make room for icon */
.course-title {
  margin-bottom: 20px;
  max-width: 65%;
}

.course-name {
  font-size: 26px; /* Slightly reduced */
  line-height: 1.2;
  font-weight: 700;
  color: white;
  margin-bottom: 12px; /* Reduced margin */
  letter-spacing: -0.5px;
}

.course-description {
  font-size: 13px; /* Slightly reduced */
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
  margin-bottom: 12px; /* Reduced margin */
}

/* Course actions - Fixed layout */
.course-actions {
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 30px;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 60px; /* Reduced from 70px */
  width: 100%;
  min-width: 70px;
  max-width: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  color: white;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  text-align: center;
}

.action-button:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
}

.action-icon {
  width: 20px; /* Reduced from 22px */
  height: 20px; /* Reduced from 22px */
  margin-bottom: 6px; /* Reduced from 8px */
  flex-shrink: 0;
  filter: brightness(0) invert(1);
  transition: transform 0.2s ease;
}

.action-button:hover .action-icon {
  transform: scale(1.1);
}

.action-text {
  font-size: 12px; /* Increased from 11px */
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

/* Lock icon style */
.lock-icon {
  margin-left: 2px;
  width: 10px;
  height: 10px;
  opacity: 0.8;
  font-size: 9px;
  vertical-align: middle;
}
  /* Locked content styling */
.action-button.locked {
  opacity: 0.6;
  cursor: not-allowed;
  background: rgba(255, 255, 255, 0.1);
}

.action-button.locked:hover::after {
  content: 'Subscribe to unlock';
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  animation: fadeIn 0.3s forwards;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Curriculum group styling */
.curriculum-group {
  margin-bottom: 50px;
}

.curriculum-title {
  position: relative;
   z-index: 20;
  font-size: 34px;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 30px;
  display: inline-block;
  padding: 0 20px;
}

.curriculum-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 80%;
  background: var(--lime-green);
  border-radius: 3px;
}

.course-row {
  margin-bottom: 20px;
}


.curriculum-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 25;
  padding-left: 20px;
}

.curriculum-logo {
  margin-right: 15px;
  position: relative;
  overflow: hidden;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: logo-entrance 0.8s ease-out forwards;
}

.ap-logo {
  max-height: 40px;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  animation: logo-pulse 3s infinite ease-in-out;
}

.curriculum-title {
  position: relative;
  z-index: 20;
  font-size: 34px;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 0;
  display: inline-block;
  padding: 0;
  background: linear-gradient(90deg, #2a2a2a, #4a4a4a, #2a2a2a);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 3s infinite linear, title-entrance 0.8s ease-out forwards;
}

.curriculum-title::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 80%;
  background: linear-gradient(to bottom, #6862e8, #9375fc);
  border-radius: 3px;
  animation: bar-grow 0.8s ease-out forwards;
}

/* New animations */
@keyframes logo-entrance {
  0% {
    transform: translateX(-30px) rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes logo-pulse {
  0%, 100% {
    transform: scale(1);
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  }
  50% {
    transform: scale(1.08);
    filter: drop-shadow(0 4px 8px rgba(0,0,0,0.3));
  }
}

@keyframes title-entrance {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bar-grow {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 80%;
    opacity: 1;
  }
}


/* Responsive styles */
@media (max-width: 1200px) {
  .course-card {
    padding: 18px; /* Reduced padding */
  }
  
  .course-name {
    font-size: 22px; /* Further reduced */
  }
  
  .course-description {
    font-size: 12px; /* Further reduced */
  }
  
  .course-icon-container {
    width: 90px; /* Further reduced */
    height: 90px; /* Further reduced */
  }
  
  .math-icon-3d {
    width: 90px; /* Further reduced */
    height: 90px; /* Further reduced */
  }
  
  .action-button {
    padding: 8px 5px;
    height: 65px;
  }
  
  .action-icon {
    width: 18px;
    height: 18px;
    margin-bottom: 5px;
  }
  
  .action-text {
    font-size: 11px; /* Further reduced */
  }
}

@media (max-width: 991px) {

 .action-text {
    font-size: 12px; /* Increased from 11px */
  }
  .course-card {
    padding: 15px; /* Further reduced */
  }
  
  .dashboard-title {
    font-size: 30px;
  }
  
  .dashboard-subtitle {
    font-size: 15px;
  }
  
  .course-name {
    font-size: 20px; /* Further reduced */
    margin-bottom: 8px;
  }
  
  .course-description {
    font-size: 11px; /* Further reduced */
    margin-bottom: 8px;
  }

  .course-description {
    text-align: center;
  }
  
 .course-title {
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

   .course-stats {
    justify-content: center;
    margin-top: 10px;
  }

  
   .course-icon-container {
    position: relative;
    width: 130px; /* Increased from 100px */
    height: 130px; /* Increased from 100px */
    margin: 0 auto 25px auto;
    top: auto;
    right: auto;
  }
  
  .math-icon-3d {
    width: 130px; /* Increased from 100px */
    height: 130px; /* Increased from 100px */
    margin: 0 auto;
  }
  
  .course-stats {
    font-size: 11px;
  }
  
 .course-actions {
    max-width: 90%; /* Change from fixed 280px to percentage */
    margin: 0 auto;
  }
  
  .action-button {
    height: 65px;
    padding: 10px 5px;
  }
  
  .action-icon {
    width: 18px;
    height: 18px;
    margin-bottom: 6px;
  }

   .course-name {
    text-align: center;
  }

   .math-icon-3d {
    transform: perspective(800px) rotateY(-10deg) rotateX(7deg);
    animation: floating-icon 6s ease-in-out infinite;
  }
  
  /* Create a continuous floating animation for mobile */
  @keyframes floating-icon {
    0% {
      transform: perspective(800px) rotateY(-10deg) rotateX(7deg) translateY(0);
    }
    50% {
      transform: perspective(800px) rotateY(-5deg) rotateX(5deg) translateY(-10px);
    }
    100% {
      transform: perspective(800px) rotateY(-10deg) rotateX(7deg) translateY(0);
    }
  }
  
  /* Disable hover effect on mobile since we have continuous animation */
  .course-card:hover .math-icon-3d {
    transform: none;
    animation: floating-icon 6s ease-in-out infinite;
  }

  .course-actions {
    max-width: 85%; /* Reduced from 90% */
    margin: 0 auto;
  }
}

.course-card {
  opacity: 0;
  transform: translateY(30px);
  transition: none;
}

/* Animation state when card becomes visible */
.course-card.reveal-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Add staggered delay for cards */
.course-col:nth-child(1) .course-card.reveal-visible {
  transition-delay: 0.1s;
}
.course-col:nth-child(2) .course-card.reveal-visible {
  transition-delay: 0.2s;
}
.course-col:nth-child(3) .course-card.reveal-visible {
  transition-delay: 0.3s;
}
.course-col:nth-child(4) .course-card.reveal-visible {
  transition-delay: 0.4s;
}
.course-col:nth-child(5) .course-card.reveal-visible {
  transition-delay: 0.5s;
}
.course-col:nth-child(6) .course-card.reveal-visible {
  transition-delay: 0.6s;
}

@media (max-width: 767px) {
  .dashboard-title {
    font-size: 28px;
  }

  .action-text {
    font-size: 11px; /* Increased from 10px */
  }
  
  .curriculum-title {
    font-size: 22px;
  }

    .course-card {
    min-height: 450px; /* Increased from 420px */
  }
  
  .course-name {
    font-size: 20px;
  }
  
  .course-category {
    font-size: 10px;
  }
  
  .course-actions {
    max-width: 95%; /* Change from fixed 260px to percentage */
    gap: 8px;
  }
  
  .action-button {
    height: 60px;
  }
  
  .action-text {
    font-size: 10px;
  }

  .course-icon-container {
    width: 120px; /* Adjusted for medium-sized screens */
    height: 120px;
  }

  .course-actions {
    max-width: 85%; /* Reduced from 90% */
    margin: 0 auto;
  }
  
  /* Make course cards narrower on mobile */
  .course-card {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .courses-dashboard {
    padding: 40px 0;
  }
  
  .dashboard-title {
    font-size: 32px;
  }
  
  .dashboard-subtitle {
    font-size: 14px;
  }
  
  .curriculum-title {
    font-size: 28px;
  }
  
  .course-name {
    font-size: 26px;
  }
  
  .course-description {
    font-size: 11px;
  }
  
    .course-icon-container {
    width: 150px; /* Increased from 70px */
    height: 150px; /* Increased from 70px */
  }
  
 .math-icon-3d {
    width: 150px;
    height: 150px;
  }
  
   .course-actions {
    max-width: 95%; /* Change from fixed 240px to percentage */
    gap: 6px;
  }
  
  .action-button {
    height: 55px;
  }
  
  .action-icon {
    width: 18px;
    height: 18px;
    margin-bottom: 4px;
  }
  
  .action-text {
    font-size: 11px; /* Increased from 10px */
  }
  
  .course-stats {
    font-size: 10px;
    gap: 8px;
  }

   .math-icon-3d {
    transform: perspective(800px) rotateY(-10deg) rotateX(7deg);
    animation: floating-icon 6s ease-in-out infinite;
  }
  
  /* Create a continuous floating animation for mobile */
  @keyframes floating-icon {
    0% {
      transform: perspective(800px) rotateY(-10deg) rotateX(7deg) translateY(0);
    }
    50% {
      transform: perspective(800px) rotateY(-5deg) rotateX(5deg) translateY(-10px);
    }
    100% {
      transform: perspective(800px) rotateY(-10deg) rotateX(7deg) translateY(0);
    }
  }
  
  /* Disable hover effect on mobile since we have continuous animation */
  .course-card:hover .math-icon-3d {
    transform: none;
    animation: floating-icon 6s ease-in-out infinite;
  }

   .course-actions {
    max-width: 85%; /* Reduced from 95% */
    gap: 10px;
  }
}

/* Small screen */
@media (max-width: 350px) {
  .course-actions {
    max-width: 92%; /* Change from fixed 220px to percentage */
  }
  
  .action-button {
    height: 50px;
  }
  
  .action-icon {
    width: 16px;
    height: 16px;
  }
  
  .action-text {
    font-size: 8px;
  }
  
  .course-name {
    font-size: 16px;
  }
  
  /* Make course cards even narrower on very small screens */
  .course-card {
    width: 85%;
    padding: 15px;
  }
}
`;

const Courses = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState(null);

  // Add refs for elements we want to observe
  const headerRef = useRef(null);
  const [headerVisible, setHeaderVisible] = useState(false);

  // Get purchased courses from Redux store
  const purchasedCourses =
    useSelector((state) => state.user.purchasedCourses) || [];
  const masterPromo = useSelector((state) => state.user.masterPromo);

  const [visibleCards, setVisibleCards] = useState({});
  // NEW: Ref for card container
  const cardGroupsRef = useRef([]);

  // Function to check if a course is purchased and active
  const isCourseAccessible = (courseId) => {
    if (masterPromo === true) {
      return true;
    }

    const course = purchasedCourses.find((c) => c.course_id === courseId);
    if (!course) return false;

    const today = new Date().getTime() / 1000;
    return course.end_date >= today;
  };

  // Fetch All Latest Courses
  const {
    data: coursesData = [],
    isLoading,
    isError,
  } = useGetAllletestcoursesQuery();

  // Function to generate random progress percentage
  const getRandomProgress = (courseId) => {
    // In a real app, this would come from user data
    return Math.floor(Math.random() * 100);
  };

  // Handle navigation logic
  const handleNavigation = (e, courseId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      e.preventDefault();
      history.push("/login");
      return;
    }

    if (!isCourseAccessible(courseId)) {
      e.preventDefault();
      history.push("/payment");
    }
  };

  // Using a scroll event listener instead of Intersection Observer
  useEffect(() => {
    // Function to check if element is in viewport
    const isElementInViewport = (element) => {
      if (!element) return false;

      const rect = element.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Element is considered "in viewport" when its top enters the bottom 80% of the screen
      return rect.top <= windowHeight * 0.9;
    };

    // Check on initial load for header
    if (isElementInViewport(headerRef.current)) {
      setHeaderVisible(true);
    }

    // Function to handle header visibility
    const handleHeaderScroll = () => {
      if (!headerVisible && isElementInViewport(headerRef.current)) {
        setHeaderVisible(true);
      }
    };

    // Function to handle card visibility
    const handleCardScroll = () => {
      cardGroupsRef.current.forEach((group, groupIndex) => {
        if (group && isElementInViewport(group)) {
          setVisibleCards((prev) => ({
            ...prev,
            [groupIndex]: true,
          }));
        }
      });
    };

    // Combined scroll handler
    const handleScroll = () => {
      handleHeaderScroll();
      handleCardScroll();
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check for cards
    handleCardScroll();

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerVisible]);

  if (isLoading) return <Loader />;
  if (isError) return <Error />;

  // Sort and group courses by curriculum
  const sortedCourses = [...coursesData].sort((a, b) =>
    a.curriculaname?.localeCompare(b.curriculaname)
  );

  const groupedCourses = sortedCourses.reduce((acc, course) => {
    const key = course.curriculaname ?? "Other";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(course);
    return acc;
  }, {});

  return (
    <>
      {/* Add the styles to the page */}
      <style dangerouslySetInnerHTML={{ __html: styles }} />

      {/* Animated background */}
      <div className="animated-background">
        <div className="bg-node"></div>
        <div className="bg-node"></div>
        <div className="bg-node"></div>
        <div className="bg-node"></div>
        <div className="bg-line"></div>
        <div className="bg-line"></div>
      </div>

      <section className="courses-dashboard">
        <Container>
          {/* Add a console log to debug */}
          <div className="dashboard-header" ref={headerRef}>
            <h2
              className={`dashboard-title ${headerVisible ? "visible" : ""}`}
              style={{
                opacity: headerVisible ? 1 : 0,
                transform: headerVisible ? "translateY(0)" : "translateY(20px)",
              }}
            >
              Latest Courses
            </h2>
            <p
              className={`dashboard-subtitle ${headerVisible ? "visible" : ""}`}
              style={{
                opacity: headerVisible ? 1 : 0,
                transform: headerVisible ? "translateY(0)" : "translateY(20px)",
              }}
            >
              Explore our interactive courses designed for K12 students
            </p>
            {/* Hidden debug element */}
            <div style={{ display: "none" }}>
              {console.log("Header visible state:", headerVisible)}
            </div>
          </div>

          {Object.entries(groupedCourses).map(
            ([curriculum, courses], groupIndex) => (
              <div
                key={curriculum}
                className="curriculum-group"
                ref={(el) => (cardGroupsRef.current[groupIndex] = el)}
              >
                <div className="curriculum-header">
  
                  <h3 className="curriculum-title">{curriculum}</h3>
                </div>
                <Row className="course-row">
                  {courses.map((course) => {
                    // Set realistic progress values based on the image
                    let progress;
                    let courseKey = "";
                    let courseColor = "";
                    let lessons = 0;
                    let workshops = 0;

                    if (course.name === "AP Calculus AB") {
                      progress = 81;
                      courseKey = "calculus-ab";
                      courseColor = "MATH";
                      lessons = 24;
                      workshops = 6;
                    } else if (course.name === "AP Calculus BC") {
                      progress = 50;
                      courseKey = "calculus-bc";
                      courseColor = "MATH";
                      lessons = 28;
                      workshops = 8;
                    } else if (course.name === "IB Math AI HL") {
                      progress = 85;
                      courseKey = "math-ai-hl";
                      courseColor = "MATH";
                      lessons = 22;
                      workshops = 5;
                    } else if (course.name === "IB Math AA HL") {
                      progress = 44;
                      courseKey = "math-aa-hl";
                      courseColor = "MATH";
                      lessons = 26;
                      workshops = 7;
                    } else if (course.name === "IB Math AI SL") {
                      progress = 26;
                      courseKey = "math-ai-sl";
                      courseColor = "MATH";
                      lessons = 18;
                      workshops = 4;
                    } else if (course.name === "IB Math AA SL") {
                      progress = 8;
                      courseKey = "math-aa-sl";
                      courseColor = "MATH";
                      lessons = 20;
                      workshops = 6;
                    } else {
                      progress = getRandomProgress(course.id);
                      courseKey = "other-course";
                      courseColor = "COURSE";
                      lessons = Math.floor(Math.random() * 20) + 10;
                      workshops = Math.floor(Math.random() * 6) + 2;
                    }

                    // Generate course description based on course name
                    const description = `Master ${course.name} with our comprehensive curriculum designed for advanced students.`;

                    return (
                      <Col
                        lg={4}
                        md={6}
                        sm={12}
                        key={course.id}
                        className="course-col"
                      >
                        <div
                          className={`course-card ${
                            visibleCards[groupIndex] ? "reveal-visible" : ""
                          }`}
                          data-course={courseKey}
                          onMouseEnter={() => setActiveCard(course.id)}
                          onMouseLeave={() => setActiveCard(null)}
                        >
                          <div className="course-top">
                            <span className="course-category">
                              {courseColor}
                            </span>
                          </div>

                          <div className="course-title">
                            <h3 className="course-name">{course.name}</h3>
                            <p className="course-description">{description}</p>

                            <div className="course-stats">
                              <div className="course-stat">
                                <svg
                                  className="stat-icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                </svg>
                                {
                                  Object.values(groupedCourses)[
                                    Object.keys(groupedCourses).indexOf(
                                      curriculum
                                    )
                                  ].length
                                }{" "}
                                courses
                              </div>
                            </div>
                          </div>

                          {/* 3D Course Icon */}
                          <div className="course-icon-container">
                            <div className="math-icon-3d">
                              <img
                                src={course.favicon_icon}
                                alt={course.name}
                                className="course-icon"
                              />
                            </div>
                          </div>

                          <div className="course-actions">
                            {/* Key Concepts Button */}
                            <NavLink
                              to={`/Key-Concept/${course.slug}`}
                              className="action-button"
                            >
                              <img
                                src={KeyIcon}
                                alt="Key Concepts"
                                className="action-icon"
                              />
                              <span className="action-text">Key Concepts</span>
                            </NavLink>

                            {/* Question Bank Button */}
                            <NavLink
                              to={`/Question-Bank/${course.slug}`}
                              className={`action-button ${
                                !isCourseAccessible(course.id) ? "locked" : ""
                              }`}
                              onClick={(e) => handleNavigation(e, course.id)}
                            >
                              <img
                                src={QuestionIcon}
                                alt="Question Bank"
                                className="action-icon"
                              />
                              <span className="action-text">Question Bank</span>
                              {!isCourseAccessible(course.id) && (
                                <span className="lock-icon">🔒</span>
                              )}
                            </NavLink>

                            {/* Past Papers Button */}
                            <NavLink
                              to={`/Past-Paper-Unit/${course.slug}`}
                              className="action-button"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location.href =
                                  "https://slyacademy.com/past-papers/";
                              }}
                            >
                              <img
                                src={PastIcon}
                                alt="Past Papers"
                                className="action-icon"
                              />
                              <span className="action-text">Past Papers</span>
                            </NavLink>

                            {/* Quizzes Button */}
                            <NavLink
                              to={`/Quiz/${course.slug}`}
                              className={`action-button ${
                                !isCourseAccessible(course.id) ? "locked" : ""
                              }`}
                              onClick={(e) => handleNavigation(e, course.id)}
                            >
                              <img
                                src={QuizIcon}
                                alt="Quizzes"
                                className="action-icon"
                              />
                              <span className="action-text">Quizzes</span>
                              {!isCourseAccessible(course.id) && (
                                <span className="lock-icon">🔒</span>
                              )}
                            </NavLink>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )
          )}
        </Container>
      </section>
    </>
  );
};

export default Courses;
