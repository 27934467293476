import React, { useState, useEffect } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom"; // Use useHistory from react-router-dom
import Header from "../layout/header/Header";
import logo_d from "../img/logo/black-logo.png";
import logo_w from "../img/logo/white-logo.png";
import { useLoginUserMutation } from "../services/aplms";
import { useGoogleLoginMutation } from "../services/aplms";
import Footer from "./Footer";
import GoogleIcon from "../img/icon/GoogleIcon.png";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image
import MailIcon from "../img/icon/MailIcon.png";

import { FaLock } from "react-icons/fa"; // <-- NEW IMPORT

const Login = () => {
  useEffect(() => {
    // Get the token from the URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // Save token to localStorage
      localStorage.setItem("auth_token", token);

      // Optional: Remove token from URL after saving
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const history = useHistory(); // Using useHistory for navigation
  const [loginUser] = useLoginUserMutation();

  const [googleLogin, { isLoading, isSuccess, data, error: googleError }] =
    useGoogleLoginMutation();

  const handleGoogleSignIn = async () => {
    try {
      const response = await googleLogin();
      // Handle the success case (e.g., store tokens, redirect, etc.)
     
    } catch (error) {
      // Handle the error case
      console.error("Login error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear the previous error when starting a new submission
    setError({ status: false, msg: "", type: "" });

    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    if (actualData.email && actualData.password) {
      try {
        const res = await loginUser(actualData);

        if (res.data && res.data.status === true) {
          localStorage.setItem("token", res.data.token);
          history.push("/");
        } else if (res.error) {
          setError({
            status: true,
            msg:
              res.error.data.message ||
              "Incorrect email or password. Please try again.",
            type: "error",
          });

          // Automatically clear the error message after 3 seconds
          setTimeout(() => {
            setError({ status: false, msg: "", type: "" });
          }, 3000); // 3000 milliseconds = 3 seconds
        }
      } catch (error) {
        setError({
          status: true,
          msg: "Something went wrong. Please try again later.",
          type: "error",
        });

        // Automatically clear the error message after 3 seconds
        setTimeout(() => {
          setError({ status: false, msg: "", type: "" });
        }, 3000);
      }
    } else {
      setError({ status: true, msg: "All fields are required", type: "error" });

      // Automatically clear the error message after 3 seconds
      setTimeout(() => {
        setError({ status: false, msg: "", type: "" });
      }, 3000);
    }
  };

  return (
    <>
      <section className="login-section">
        <Header />
        <div className="desktop-spacer"></div>
        <img
          src={BGRight1}
          alt="Splatter"
          className="splatter-image-right-1-login"
        />
        <img
          src={BGRight2}
          alt="Splatter"
          className="splatter-image-right-2-login"
        />
        <img
          src={BGMiddle}
          alt="Splatter"
          className="splatter-image-middle-login"
        />
        <Container>
          <Row className="login-from">
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_w} className="drack" alt="logo" />
                </Link>
                <Link to="/">
                  <img src={logo_d} className="lite" alt="logo" />
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div className="">
                <h4>Login</h4>

                {error.status && (
                  <div className={`error-message show ${error.type}`}>
                    {error.msg}
                  </div>
                )}

                <p className="from-bottom-text">
                  Access your account and continue your journey.
                </p>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4 email-input-group"
                    controlId="formBasicEmail"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={MailIcon}
                        alt="Mail Icon"
                        className="input-icon"
                      />
                      <input
                        className="custom-input email-input"
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-2 password-input-group"
                    controlId="formBasicPassword"
                  >
                    <div className="input-icon-wrapper">
                      <FaLock
                        className="input-icon-lock"
                        style={{ fontSize: "22px", color: "white" }}
                      />
                      <input
                        type="password"
                        className="custom-input password-input"
                        placeholder="Password"
                        name="password"
                      />
                    </div>
                  </Form.Group>

                  <Button className="Signup-Button" type="submit">
                    Login
                  </Button>

                  <h5 className="continue-with-text">Or continue with...</h5>

                  <Button
                    className="Signup-Button-Google" // <-- Use the same .Signup-Button class
                    onClick={() => {
                      window.location.href =
                        "https://num8ers.cloud/api/auth/google";
                    }}
                  >
                    <img
                      src={GoogleIcon}
                      alt="Google Icon"
                      className="google-icon"
                    />
                    <span className="google-text">Google</span>
                  </Button>

                  <p className="from-bottom-text">
                    By clicking on Login, you agree to our{" "}
                    <a
                      href="/TermsOfUse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href="/Privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Privacy policy
                    </a>
                    .
                  </p>

                  <p className="forgot-password-text">
                    Don't have an account?{" "}
                    <Link to="/Sign-Up" className="login-button-style">
                      Sign Up
                    </Link>
                  </p>

                  <p className="forgot-password-text">
                    Forgot Password?{" "}
                    <Link to="/forgot" className="login-button-style">
                      Click here
                    </Link>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>
    </>
  );
};

export default Login;
