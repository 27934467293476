import { React, useState, useEffect } from "react";
import Right_Arror from "../../img/icon/right-arror.png";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import "./header.css";
import { useGetAllsubjectQuery } from "../../services/aplms";
import Right_Arror_d from "../../img/icon/rigth_arror_drack.png";
import Row from "react-bootstrap/Row";
import Ritsideheader from "./Ritsideheader";
import Loader from "../../loader/Loader";


const Sideheader = (props) => {
  const slug = props.propsintroData;
  const couresdata = useGetAllsubjectQuery(slug);

  // Set initial state to null or empty string
  const [selectedSlug, setSelectedSlug] = useState(null);

  // Use useEffect to update selectedSlug once data is available
  useEffect(() => {
    if (couresdata.data && couresdata.data.length > 0) {
      setSelectedSlug(couresdata.data[0].slug);
    }
  }, [couresdata.data]);

  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data && couresdata.data.length === 0)
    return (
      <div
        style={{
          fontFamily: "Nunito",
          fontSize: "18px",
          textAlign: "center",
          padding: "20px",
          color: "#333",
        }}
      >
        Coming Soon
      </div>
    );
  if (couresdata.isError)
    return (
      <div
        style={{
          fontFamily: "Nunito",
          fontSize: "18px",
          textAlign: "center",
          padding: "20px",
          color: "#333",
        }}
      >
        Coming Soon
      </div>
    );

  const handleNavLinkClick = (subjectSlug) => {
    setSelectedSlug(subjectSlug);
  };

  

  return (
    <>
      <Row>
        <Col sm={2}>
          {couresdata.data.map((subject) => {
            return (
              <Nav variant="pills" className="flex-column" key={subject.slug}>
                <Nav.Item className="megamenu-left-tab">
                  <Nav.Link
                    eventKey={subject.slug}
                    onClick={() => handleNavLinkClick(subject.slug)}
                    active={selectedSlug === subject.slug}
                  >
                    {subject.name}
                    <img src={Right_Arror} className="d" alt="Right Arrow" />
                    <img
                      src={Right_Arror_d}
                      className="w"
                      alt="Right Arrow Dark"
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            );
          })}
        </Col>
        <Col sm={10}>
          <Ritsideheader propsData={selectedSlug} />
        </Col>
      </Row>
    </>
  );
};

export default Sideheader;
