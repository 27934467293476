import React, { useState } from "react";
import "./login.css"; // Ensure you have this CSS file
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import logo_d from "../img/logo/black-logo.png";
import logo_w from "../img/logo/white-logo.png";
import Footer from "./Footer";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";
import MailIcon from "../img/icon/MailIcon.png";
import Spinner from "react-bootstrap/Spinner";

const Forgot = () => {
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous feedback
    setError({ status: false, msg: "", type: "" });
    
    // Set loading state to true
    setIsLoading(true);

    const data = new FormData(e.currentTarget);
    const email = data.get("email");

    // Validate that an email is provided
    if (!email) {
      setError({
        status: true,
        msg: "Please provide an email address.",
        type: "error",
      });
      setIsLoading(false);
      return;
    }

    // Validate the email format using a regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError({
        status: true,
        msg: "Please provide a valid email address.",
        type: "error",
      });
      setIsLoading(false);
      return;
    }

    const actualData = { email };

    try {
      const response = await fetch(
        "https://num8ers.cloud/api/student/apsend-reset-password-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(actualData),
        }
      );

      const resData = await response.json();

      // Set loading state to false after response is received
      setIsLoading(false);

      if (response.ok && resData.status === "success") {
        setError({
          status: true,
          msg: "Password Reset Email Sent. Check Your Email!",
          type: "success",
        });
      } else {
        console.error("Error from password reset API:", resData);
        setError({
          status: true,
          msg: resData.message || "Something went wrong. Please try again.",
          type: "error",
        });
      }
    } catch (err) {
      console.error("Unhandled error during password reset:", err);
      setIsLoading(false);
      setError({
        status: true,
        msg: "Something went wrong. Please try again later.",
        type: "error",
      });
    }
  };

  return (
    <>
      <section className="login-section">
        <Header />
        <div style={{ height: "100px" }}></div>
        <img
          src={BGRight1}
          alt="Splatter"
          className="splatter-image-right-1-login"
        />
        <img
          src={BGRight2}
          alt="Splatter"
          className="splatter-image-right-2-login"
        />
        <img
          src={BGMiddle}
          alt="Splatter"
          className="splatter-image-middle-login"
        />
        <Container>
          <Row className="login-from">
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_w} className="drack" alt="logo" />
                </Link>
                <Link to="/">
                  <img src={logo_d} className="lite" alt="logo" />
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div>
                <h4>Forgot Password</h4>

                {/* Conditionally render the message */}
                {error.status && (
                  <div
                    className={`error-message show ${
                      error.type === "success" ? "success" : "error"
                    }`}
                  >
                    {error.msg}
                  </div>
                )}

                <p className="from-bottom-text">
                  Enter your email to reset your password.
                </p>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4 email-input-group"
                    controlId="formBasicEmail"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={MailIcon}
                        alt="Mail Icon"
                        className="input-icon"
                      />
                      <input
                        className="custom-input email-input"
                        placeholder="Email Address"
                        name="email"
                        disabled={isLoading}
                      />
                    </div>
                  </Form.Group>

                  <Button 
                    className="Signup-Button" 
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Sending...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>

                  <p className="from-bottom-text">
                    By clicking on Login, you agree to our{" "}
                    <a
                      href="/TermsOfUse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href="/Privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-button-style"
                    >
                      Privacy policy
                    </a>
                    .
                  </p>

                  <p className="forgot-password-text">
                    Remembered your password?{" "}
                    <Link to="/login" className="login-button-style">
                      Login
                    </Link>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>
    </>
  );
};

export default Forgot;