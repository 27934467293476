import React from "react";
import "./ErrorPayment.css"; // Import the separate ErrorPayment stylesheet
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";

// Replace these imports with your actual image paths
import ErrorIcon from "../img/redcrossicon.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";

const ErrorPayment = () => {
  return (
    <section className="error-page">
      <Header />

      {/* Background images (similar to Success, but optional) */}
      <img
        src={BGRight2}
        alt="Splatter"
        className="splatter-image-right-error"
      />


      <div className="error-background">
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="error-box d-flex flex-wrap align-items-center">
                {/* Left side: icon + heading + amount */}
                <div className="error-box-left text-center me-md-4">
                  <img
                    src={ErrorIcon}
                    alt="Error Icon"
                    className="error-icon"
                  />
                  <p className="error-title">Payment Unsuccessful</p>
                </div>

                {/* Divider for wider screens */}
                <div className="error-divider d-none d-md-block" />

                {/* Right side: payment details */}
                <div className="error-box-right mt-4 mt-md-0">
                  <div className="list-payment-box">
                    <h4>We couldn’t process your payment.</h4>
                    <p>
                      Please try again, contact your bank or try a different payment
                      method. If you believe this is an error, feel free to
                      reach out to our support team.
                    </p>
                    <a href="/contact-us" >
                      Contact Support
                    </a>
                  </div>
                </div>
                {/* end of right side */}
              </div>
              {/* end of error-box */}
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </section>
  );
};

export default ErrorPayment;
