// AlertModal.jsx
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from "react-icons/fa";
import "./AlertModal.css";

// Theme colors
const THEME_COLOR = "rgb(91, 147, 17)";
const THEME_COLOR_LIGHT = "rgba(91, 147, 17, 0.1)";
const THEME_COLOR_MEDIUM = "rgba(91, 147, 17, 0.6)";

// Alert types
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

/**
 * Self-contained AlertModal component that can be used anywhere in your application
 * without requiring a context provider.
 */
const AlertModal = () => {
  // Internal state for the modal
  const [alertState, setAlertState] = useState({
    isOpen: false,
    title: "",
    message: "",
    type: ALERT_TYPES.INFO,
    confirmText: "Yes",
    cancelText: "No",
    onConfirm: () => {},
    onCancel: () => {},
    showCancel: false,
  });

  // Create a public interface for the component
  const alertAPI = {
    // Show a simple alert with OK button
    showAlert: (message, options = {}) => {
      setAlertState({
        isOpen: true,
        title: options.title || "Notification",
        message,
        type: options.type || ALERT_TYPES.INFO,
        confirmText: options.confirmText || "OK",
        onConfirm: () => {
          setAlertState((prev) => ({ ...prev, isOpen: false }));
          if (options.onConfirm) options.onConfirm();
        },
        onCancel: () => {
          setAlertState((prev) => ({ ...prev, isOpen: false }));
        },
        showCancel: false,
      });
    },

    // Show a confirmation alert with Yes/No buttons
    showConfirm: (message, options = {}) => {
      setAlertState({
        isOpen: true,
        title: options.title || "Confirmation",
        message,
        type: options.type || ALERT_TYPES.WARNING,
        confirmText: options.confirmText || "Yes",
        cancelText: options.cancelText || "No",
        onConfirm: () => {
          setAlertState((prev) => ({ ...prev, isOpen: false }));
          if (options.onConfirm) options.onConfirm();
        },
        onCancel: () => {
          setAlertState((prev) => ({ ...prev, isOpen: false }));
          if (options.onCancel) options.onCancel();
        },
        showCancel: true,
      });
    },

    // Close the alert
    closeAlert: () => {
      setAlertState((prev) => ({ ...prev, isOpen: false }));
    },
  };

  // Icon based on alert type
  const renderIcon = () => {
    const iconColor = getIconColor();
    const style = { color: iconColor };

    switch (alertState.type) {
      case ALERT_TYPES.SUCCESS:
        return <FaCheckCircle style={style} size={24} />;
      case ALERT_TYPES.WARNING:
        return <FaExclamationTriangle style={style} size={24} />;
      case ALERT_TYPES.ERROR:
        return <FaTimesCircle style={style} size={24} />;
      case ALERT_TYPES.INFO:
      default:
        return <FaInfoCircle style={style} size={24} />;
    }
  };

  // Get icon color based on alert type
  const getIconColor = () => {
    switch (alertState.type) {
      case ALERT_TYPES.SUCCESS:
        return THEME_COLOR;
      case ALERT_TYPES.WARNING:
        return "#f0ad4e";
      case ALERT_TYPES.ERROR:
        return "#d9534f";
      case ALERT_TYPES.INFO:
      default:
        return THEME_COLOR;
    }
  };

  // Get button styling based on alert type - forcing green color
  const getButtonStyle = () => {
    switch (alertState.type) {
      case ALERT_TYPES.SUCCESS:
      case ALERT_TYPES.INFO:
      case ALERT_TYPES.WARNING:
      case ALERT_TYPES.ERROR:
      default:
        return { 
          backgroundColor: THEME_COLOR, 
          borderColor: THEME_COLOR,
          color: "white" 
        };
    }
  };

  // Get header background color based on alert type
  const getHeaderStyle = () => {
    switch (alertState.type) {
      case ALERT_TYPES.SUCCESS:
        return { backgroundColor: THEME_COLOR_LIGHT };
      case ALERT_TYPES.WARNING:
        return { backgroundColor: THEME_COLOR_LIGHT };
      case ALERT_TYPES.ERROR:
        return { backgroundColor: THEME_COLOR_LIGHT };
      case ALERT_TYPES.INFO:
      default:
        return { backgroundColor: THEME_COLOR_LIGHT };
    }
  };

  // Get text color based on alert type
  const getTextColor = () => {
    switch (alertState.type) {
      case ALERT_TYPES.SUCCESS:
      case ALERT_TYPES.WARNING:
      case ALERT_TYPES.ERROR:
      case ALERT_TYPES.INFO:
      default:
        return THEME_COLOR;
    }
  };

  // Expose the API through a React ref to allow external components to call it
  // This is stored on the component's function object
  AlertModal.api = alertAPI;

  return (
    <Modal 
      show={alertState.isOpen} 
      onHide={alertAPI.closeAlert} 
      centered
      className="custom-alert-modal"
    >
      <Modal.Header style={getHeaderStyle()} className="custom-modal-header">
        <Modal.Title className="d-flex align-items-center">
          {renderIcon()}
          <span className="ms-2" style={{ color: getTextColor() }}>
            {alertState.title}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">{alertState.message}</Modal.Body>
      <Modal.Footer className="custom-modal-footer d-flex justify-content-center">
        <Button 
          style={getButtonStyle()}
          onClick={alertState.onConfirm}
          className="custom-confirm-button mx-2"
        >
          {alertState.confirmText}
        </Button>
        
        {alertState.showCancel && (
          <Button 
            variant="outline-secondary" 
            onClick={alertState.onCancel}
            className="custom-cancel-button mx-2"
            style={{ color: THEME_COLOR, borderColor: THEME_COLOR }}
          >
            {alertState.cancelText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;