import React from "react";
import "./ApCourseCard.css";
import Col from "react-bootstrap/Col";

import Key from "../../img/icon/Molecule_light_w.png";
import Question from "../../img/icon/Quiz_w.png";
import Past from "../../img/icon/Book_open_alt_light_w.png";
import Quiz from "../../img/icon/Book_w.png";

import { NavLink } from "react-router-dom";
import { useGetAllcoursesQuery } from "../../services/aplms";
import Loader from "../../loader/Loader";
import Error from "../../errordata/Error";

const Coursecard = (props) => {
  window.scrollTo(0, 0);
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);

  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null || couresdata.isError)
    return (
      <div>
        <Error />
      </div>
    );

  // Softer, more professional color themes
  const colorThemes = [
    {
      name: "purple",
      gradient: "linear-gradient(135deg, #9C8CDB, #6D5CB0)",
      accent: "#B8A9F1"
    },
    {
      name: "blue",
      gradient: "linear-gradient(135deg, #6993D6, #3D6CB3)",
      accent: "#8FB6FF"
    },
    {
      name: "green",
      gradient: "linear-gradient(135deg, #4AA396, #2C7A70)", // Darker green
      accent: "#7FC7BD"
    },
    {
      name: "red",
      gradient: "linear-gradient(135deg, #D15E70, #A64553)", // Darker red
      accent: "#E88695"
    }
  ];
  return (
    <>
      {couresdata.data.map((course, index) => {
        const theme = colorThemes[index % colorThemes.length];

        return (
          <Col sm={3} xs={12} key={course.id} className="course-card-column" style={{ position: 'relative', zIndex: 90 }}>
            <div 
              className={`apcoursecard-box ${theme.name}-theme`}
              style={{
                background: theme.gradient
              }}
            >
              <div className="card-shine"></div>
              <div className="apcoursecard-content">
                <h4>{course.name}</h4>
                <ul className="apcoursecard-box-content">
                  <li className="apcoursecard-box-content-button">
                    <NavLink
                      to={`/Key-Concept/${course.slug}`}
                      className="apcoursecard-navlink"
                    >
                      <span className="apcoursecard-icon-wrapper">
                        <img src={Key} alt="Key Concept" />
                      </span>
                      <span className="navlink-text">Key Concepts</span>
                    </NavLink>
                  </li>

                  <li className="apcoursecard-box-content-button">
                    <NavLink
                      to={`/Question-Bank/${course.slug}`}
                      className="apcoursecard-navlink"
                    >
                      <span className="apcoursecard-icon-wrapper">
                        <img src={Question} alt="Question Bank" />
                      </span>
                      <span className="navlink-text">Question Bank</span>
                    </NavLink>
                  </li>

                  <li className="apcoursecard-box-content-button">
                    <NavLink
                      to={`/Past-Paper-Unit/${course.slug}`}
                      className="apcoursecard-navlink"
                    >
                      <span className="apcoursecard-icon-wrapper">
                        <img src={Past} alt="Past Papers" />
                      </span>
                      <span className="navlink-text">Past Papers</span>
                    </NavLink>
                  </li>

                  <li className="apcoursecard-box-content-button">
                    <NavLink
                      to={`/Quiz/${course.slug}`}
                      className="apcoursecard-navlink"
                    >
                      <span className="apcoursecard-icon-wrapper">
                        <img src={Quiz} alt="Quizzes" />
                      </span>
                      <span className="navlink-text">Quizzes</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default Coursecard;