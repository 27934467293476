import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useGetAllcoursesQuery } from "../../services/aplms";
import Loader from "../../loader/Loader";
import { NavLink } from "react-router-dom";

const Ritsideheader = (props, key) => {
  const slug = props.propsData;

  const couresdata = useGetAllcoursesQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div
        style={{
          fontFamily: "Nunito",
          fontSize: "18px",
          textAlign: "center",
          padding: "20px",
          color: "#333",
        }}
      >
        Coming Soon
      </div>
    );
  if (couresdata.isError)
    return (
      <div
        style={{
          fontFamily: "Nunito",
          fontSize: "18px",
          textAlign: "center",
          padding: "20px",
          color: "#333",
        }}
      >
        Coming Soon
      </div>
    );

  return (
    <>
      <Col sm={10}>
        <Tab.Content>
          <Tab.Pane eventKey={slug} active={true}>
            <Breadcrumb className="custom-breadcrumb">
              <Breadcrumb.Item href="#">
                {couresdata.data[0].curriculaname}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {couresdata.data[0].subname}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Row className="row-background">
              {couresdata.data.map((coures) => (
                <Col sm={3} key={coures.slug}>
                  <h6 className="top_heading">{coures.name}</h6>
                  <hr className="divider" />
                  <ul className="list-unstyled megamenu-heading-top">
                    <li className="nav-item">
                      <NavLink
                        to={`/Key-Concept/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Key Concepts
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Question-Bank/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Question Bank
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Past-Paper-Unit/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Past Papers
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Quiz/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Quizzes
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              ))}
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Col>
    </>
  );
};

export default Ritsideheader;
